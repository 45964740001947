export const conducts = [
    {
        content:"Respectful"
    },
    {
        content:"Well-Behaving"
    },
    {
        content:"Disrespectful"
    },
    {
        content:"Calm"
    },
    {
        content:"Humble"
    },
    {
        content:"Approachable"
    },
    {
        content:"Bully"
    },
    {
    content:"Truant"
    }
]

export const attitudes = [
    {
        content:"Hardworking"
    },
    {
        content:"Dependable"
    },
    {
        content:"Not Serious in Class"
    },
    {
        content:"Lazy"
    },
    {
        content:"Slow"
    }
]

export const interests = [
    {
        content:"Running"
    },
    {
        content:"Reading"
    },
    {
        content:"Drumming"
    },
    {
        content:"Sports"
    },
    {
        content:"Singing"
    },
    {
        content:"Dancing"
    }
]

export const remarks = [
    {
        content:"Has Improved"
    },
    {
        content:"Keep it up"
    },
    {
        content:"More Room for Improvement"
    },
    {
        content:"Hardworking"
    },
    {
        content:"Must buck up"
    },
    {
        content:"Good at Maths"
    }
]
