export const all_subjects = [
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English Langu.',
        value: 'English Language'
    },

    {
        text: 'Integ.  Science',
        value: 'Integrated Science'
    },

    {
        text: 'Social Studies',
        value: 'Social Studies'
    },
    {
        text: 'Info & Comm. Tech',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'R. M . E',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'Basic Design & T and Tech',
        value: 'Basic Design & Tech.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'Our World Our People',
        value: 'Our World Our People'
    },
    {
        text: 'Fantse',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    }
]