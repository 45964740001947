import React from "react";
import {
  Form,
  Image,
  Input,
  Button,
  Header,
  Grid,
  Loader,
  Dimmer,
  Icon,
} from "semantic-ui-react";
import _ from "lodash";
import Feedback from "../_calls/feedback";
import { auth, db } from "../services";
import logo from "../images/logo.png";

export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      staff_id: "",
      password_1: "",
      password_2: "",
      password: "",
      loading: true,
      tapped: false,
      opn: false,
      activate_login: true,
      _staff: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    db.collection("staff_registration").onSnapshot((qss) => {
      let curStaff = [];
      qss.forEach((doc) => {
        if (
          doc.data().employee_category === "Teacher" &&
          (doc.data().status === undefined || doc.data().status === "active")
        ) {
          curStaff.push({ ...doc.data(), _id: doc.id });
        }
      });
      this.setState({ _staff: curStaff });
      curStaff = [];
    });
    setTimeout(() => this.setState({ loading: false }), 5000);
  }

  addNewStaff = (event) => {
    event.preventDefault();
    //check password identity
    if (this.state.password_1 === this.state.password_2) {
      let status = _.find(this.state._staff, (nsd) => {
        return (
          nsd.staff_id === this.state.staff_id &&
          (nsd.teacher_type === "Class Teacher" ||
            nsd.teacher_type === "Subject and Class")
        );
      });
      if (status === undefined) {
        this.setState({
          tapped: false,
          opn: true,
          message: "Cannot Register, Staff ID not recognized",
        });
      } else {
        let new_mail = this.state.staff_id.trim() + "@wrisgh.com";

        auth()
          .createUserWithEmailAndPassword(new_mail, this.state.password_1)
          .catch((err) => {
            if (err.code === "auth/weak-password") {
              this.setState({ tapped: false, opn: true, message: err.message });
            } else {
              this.setState({ tapped: false, opn: true, message: err.message });
            }
          });
      }
    } else {
      this.setState({
        tapped: false,
        opn: true,
        message: "Password do not Match",
        password_1: "",
        password_2: "",
      });
    }
  };
  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ tapped: true });
    try {
      //check if staff is recognized
      let status = _.find(this.state._staff, (sd) => {
        return sd.staff_id === this.state.staff_id;
      });
      if (status === undefined) {
        this.setState({
          opn: true,
          message: "Staff ID is not recognized",
          tapped: false,
        });
      } else {
        let new_mail = this.state.staff_id.trim().toLowerCase() + "@wrisgh.com";
        await auth().signInWithEmailAndPassword(new_mail, this.state.password);
      }
    } catch (err) {
      this.setState({ tapped: false, opn: true, message: err.message });
    }
  }

  render() {
    return (
      <>
        <Feedback
          open_feedback={this.state.opn}
          message_type="error"
          close_feedback={(e) => this.setState({ opn: false })}
          message={this.state.message}
        />

        <Grid columns={2} stackable>
          <Grid.Column
            computer={6}
            tablet={6}
            mobile={16}
            className="form-side"
          >
            {this.state.loading ? (
              <div className="loader-div">
                <Dimmer active>
                  <Loader indeterminate size="massive" />
                </Dimmer>
              </div>
            ) : (
              <div className="main-form">
                <Image
                  className="relaxed"
                  src={logo}
                  size="tiny"
                  centered
                  circular
                />
                {this.state.activate_login ? (
                  <Form onSubmit={this.handleSubmit} autoComplete="off">
                    <Header
                      as="h2"
                      textAlign="center"
                      content={"Staff Portal"}
                      subheader={"Enter credentials to contiue"}
                    />
                    <Form.Field
                      fluid
                      icon={{ color: "user", name: "user" }}
                      label="Staff ID"
                      required
                      control={Input}
                      onChange={(e) =>
                        this.setState({ staff_id: e.target.value })
                      }
                    />
                    <Form.Field
                      fluid
                      icon={{ color: "red", name: "lock" }}
                      label="Password: "
                      required
                      control={Input}
                      type="password"
                      value={this.state.password}
                      onChange={(e) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                    <Button
                      loading={this.state.tapped}
                      size="tiny"
                      type="submit"
                      color="orange"
                    >
                      Sign In
                    </Button>{" "}
                    &nbsp; &nbsp; &nbsp; {/*eslint-disable-next-line*/}
                    <br />
                    <br />
                    <p>
                      Not Registered?{" "}
                      <span
                        style={{
                          border: "none",
                          cursor: "pointer",
                          color: "orange",
                        }}
                        onClick={() => this.setState({ activate_login: false })}
                      >
                        Create Account
                      </span>
                    </p>
                  </Form>
                ) : (
                  <Form onSubmit={this.addNewStaff} autoComplete="off">
                    <Form.Field
                      required
                      label="Staff ID"
                      control={Input}
                      value={this.state.staff_id}
                      onChange={(e) =>
                        this.setState({ staff_id: e.target.value })
                      }
                    />
                    <Form.Field
                      required
                      label="Password"
                      onChange={(e) =>
                        this.setState({ password_1: e.target.value })
                      }
                      type="password"
                      control={Input}
                      value={this.state.password_1}
                    />

                    <Form.Field
                      required
                      label="Confirm Password"
                      type="password"
                      onChange={(e) =>
                        this.setState({ password_2: e.target.value })
                      }
                      control={Input}
                      value={this.state.password_2}
                    />

                    <Button
                      color="green"
                      size="tiny"
                      type="sumbit"
                      loading={this.state.tapped}
                    >
                      <Icon name="checkmark" /> Register
                    </Button>
                    <br />
                    <br />
                    <p>
                      Already Registered ?{" "}
                      <span
                        style={{
                          border: "none",
                          cursor: "pointer",
                          color: "orange",
                        }}
                        onClick={() => this.setState({ activate_login: true })}
                      >
                        Login
                      </span>
                    </p>
                  </Form>
                )}
              </div>
            )}
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={10}
            className="login-side"
          ></Grid.Column>
        </Grid>
      </>
    );
  }
}
