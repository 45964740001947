import React, { Component } from 'react';
import _ from 'lodash'
import { Grid, Segment, Divider, Statistic, Image, Checkbox, Card, Loader, Table, Button, Icon, TransitionablePortal, Header } from 'semantic-ui-react'
import Feedback from '../_calls/feedback'

import {db} from '../services'


class Division extends Component {
    constructor() {
        super()
        this.state = {
            currently_selected: 'Nursery',
            current_class: [],
            loading: false,
            open_feedback: false,
            preview: null,
            show_modal: false,
            confirm_: false,
            show_group_modal:false,
            open_group_feedback:false,
            message: '',
            checked: true,
            marked:[]
        }
    }


    handleType = (e, target) => {
        this.setState({ currently_selected: target.value,marked:[] }, () => {
            db.collection('student_registration').where("class_admitted", "==", this.props.profile.class_assigned).onSnapshot(querySnapShot => {
                var _current_ = []
                querySnapShot.forEach(doc => {
                    _current_.push({ ...doc.data(), _id: doc.id })
                })
                this.setState({ current_class: _.filter(_current_, function (std) { return std.status !== 'in-active' }), loading: false, preview: null }, () => { _current_ = [] })
            })
        })
    }

    confirmProcess = status => event => {
        this.setState({ show_modal: true, status: status, _id: this.state.preview._id })
    }

    demoteStudent = _id => {
        let demote_to
        let class_category
        if (this.props.profile.class_assigned === 'Kindergaten 1') {
            demote_to = 'Nursery'
            class_category = 'basic'
        }
        else if (this.props.profile.class_assigned === 'Kindergaten 2') {
            demote_to = 'Kindergaten 1'
            class_category = 'basic'

        } else if (this.props.profile.class_assigned === 'Primary 1') {
            demote_to = 'Kindergaten 2'
            class_category = 'basic'
        }
        else if (this.props.profile.class_assigned === 'Primary 2') {
            demote_to = 'Primary 1'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 3') {
            demote_to = 'Primary 2'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 4') {
            demote_to = 'Primary 3'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 5') {
            demote_to = 'Primary 4'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 6') {
            demote_to = 'Primary 5'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Junior High 1') {
            demote_to = 'Primary 6'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Junior High 2') {
            demote_to = 'Junior High 1'
            class_category = 'junior_high'
        }
        else if (this.props.profile.class_assigned === 'Junior High 3') {
            demote_to = 'Junior High 2'
            class_category = 'junior_high'
        }


        var demoteRef = db.collection('student_registration').doc(this.state.preview._id)

        return demoteRef.update({ class_admitted: demote_to, class_category: class_category }).then(res => { this.setState({ show_modal: false, message: 'Student Demotion ', open_feedback: true, preview: null }) }).catch(err => console.log(err.message))
    }

    promoteStudent = _id => {
        let promote_to
        let class_category
        if (this.props.profile.class_assigned === 'Nursery') {
            promote_to = 'Kindergaten 1'
            class_category = 'basic'
        }
        else if (this.props.profile.class_assigned === 'Kindergaten 1') {
            promote_to = 'Kindergaten 2'
            class_category = 'basic'

        }
        else if (this.props.profile.class_assigned === 'Kindergaten 2') {
            promote_to = 'Primary 1'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 1') {
            promote_to = 'Primary 2'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 2') {
            promote_to = 'Primary 3'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 4') {
            promote_to = 'Primary 5'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 5') {
            promote_to = 'Primary 6'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 6') {
            promote_to = 'Junior High 1'
            class_category = 'junior_high'
        }
        else if (this.props.profile.class_assigned === 'Junior High 1') {
            promote_to = 'Junior High 2'
            class_category = 'junior_high'
        }
        else if (this.props.profile.class_assigned === 'Junior High 2') {
            promote_to = 'Junior High 3'
            class_category = 'junior_high'
        }


        var promoteRef = db.collection('student_registration').doc(this.state.preview._id)

        return promoteRef.update({ class_admitted: promote_to, class_category: class_category }).then(res => { this.setState({ show_modal: false, message: 'Student Promotion ', open_feedback: true, preview: null }) }).catch(err => console.log(err.message))
    }

    setMassInActive = event => {
        let total = 0
        this.setState({show_group_modal:false}) 

        let inactiveRef = db.collection('student_registration')

        this.state.marked.forEach(std => {
            inactiveRef.doc(std.doc_).update({status:'in-active'})
            total +=1
        })

        if(total === this.state.marked.length){
            this.setState({open_group_feedback:true, message:"Status set to in-active"},() => this.setState({marked:[]}))
        }

    }
    setMassPromotion = event => {

        let promote_to
        let total = 0
        let class_category

        this.setState({show_group_modal:false})

        if (this.props.profile.class_assigned === 'Nursery') {
            promote_to = 'Kindergaten 1'
            class_category = 'basic'
        }
        else if (this.props.profile.class_assigned === 'Kindergaten 1') {
            promote_to = 'Kindergaten 2'
            class_category = 'basic'

        }
        else if (this.props.profile.class_assigned === 'Kindergaten 2') {
            promote_to = 'Primary 1'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 1') {
            promote_to = 'Primary 2'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 2') {
            promote_to = 'Primary 3'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 4') {
            promote_to = 'Primary 5'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 5') {
            promote_to = 'Primary 6'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 6') {
            promote_to = 'Junior High 1'
            class_category = 'junior_high'
        }
        else if (this.props.profile.class_assigned === 'Junior High 1') {
            promote_to = 'Junior High 2'
            class_category = 'junior_high'
        }
        else if (this.props.profile.class_assigned === 'Junior High 2') {
            promote_to = 'Junior High 3'
            class_category = 'junior_high'
        }
        
        const promoteRef = db.collection('student_registration')

        this.state.marked.forEach(std => { 
            promoteRef.doc(std.doc_).update({class_admitted: promote_to, class_category:class_category})

            total += 1
        })

        if(total === this.state.marked.length){
            this.setState({open_group_feedback: true, message:"Mass Promotion Successful"},() => this.setState({marked:[]}))
        }else{
            console.log("There is something wront with " + total)
        }


    }

    setMassDemotion = event => {
        let demote_to
        let total = 0
        let class_category
        this.setState({show_group_modal:false})
        if (this.props.profile.class_assigned === 'Kindergaten 1') {
            demote_to = 'Nursery'
            class_category = 'basic'
        }
        else if (this.props.profile.class_assigned === 'Kindergaten 2') {
            demote_to = 'Kindergaten 1'
            class_category = 'basic'
    
        } else if (this.props.profile.class_assigned === 'Primary 1') {
            demote_to = 'Kindergaten 2'
            class_category = 'basic'
        }
        else if (this.props.profile.class_assigned === 'Primary 2') {
            demote_to = 'Primary 1'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 3') {
            demote_to = 'Primary 2'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 4') {
            demote_to = 'Primary 3'
            class_category = 'lower_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 5') {
            demote_to = 'Primary 4'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Primary 6') {
            demote_to = 'Primary 5'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Junior High 1') {
            demote_to = 'Primary 6'
            class_category = 'upper_primary'
        }
        else if (this.props.profile.class_assigned === 'Junior High 2') {
            demote_to = 'Junior High 1'
            class_category = 'junior_high'
        }
        else if (this.props.profile.class_assigned === 'Junior High 3') {
            demote_to = 'Junior High 2'
            class_category = 'junior_high'
        }
    
        const demoteRef = db.collection('student_registration')
    
        this.state.marked.forEach(std =>{ 
            demoteRef.doc(std.doc_).update({class_admitted: demote_to, class_category:class_category})
            total += 1
        })
    
        if(total === this.state.marked.length){
            this.setState({open_group_feedback: true, message:"Mass Demotion Success"},() => this.setState({marked:[]}))
        }else{
            console.log("There is something wrong with " + total)
        }
    }

    renderProfileInactive = _id => {
        db.collection('student_registration').doc(this.state.preview._id).update({ status: 'in-active' }).then(resp => this.setState({
            show_modal: false, open_feedback: true, message: 'Profile Inactivation ', preview: null
        })).catch(err => console.log(err.message))
    }

    getTermType = (cl) => {
        let dec =  cl.charAt(0) === 'J' || cl === "Basic 7" || cl === "Basic 8" || cl === "Basic 9" || cl === "Basic 10"
        
        return dec === true ? this.props.settings.current_semester : this.props.settings.current_term
    }

    getPaymentStatus = _id => {
        let history = _.filter(this.props.fees,(fh) => fh.term_paid === this.getTermType(this.props.profile.class_assigned) && fh.student_id === _id && new Date(fh.date_paid).getFullYear() === new Date().getFullYear() && fh.class_admitted === this.props.profile.class_assigned)
        if(history.length === 0){
            return <><Icon name="cancel" color="red"/>  Not Paid </>
        }else{
            return <><Icon name="checkmark"  color="green"/> Paid: {_.sumBy(history, (fh) => fh.amount_paid)}</>
        }
    }



    makeSelections = (checked, doc_id, _id) => {
            const status = _.find(this.state.marked,(std) => {return std.student_id === _id && std.marked !== checked})

            if(checked === true && status === undefined){ 
                this.setState({
                    marked:[...this.state.marked,{
                        student_id:_id,
                        doc_:doc_id,
                        marked:true
                    }]
                })
            }else {
                let original_state = this.state.marked
                let _removed = _.remove(original_state,(rmd) => {return rmd.marked !== checked && rmd.student_id === _id})
                this.setState({marked:original_state})
                console.log(_removed)
                
                // this.setState({
                //     marked:[this.state.marked.pop(this.state.marked.indexOf({status}))]
                // })
            }
    }

    render() {
  
        return (
            <Grid className='open-grids'>
                <Feedback message={this.state.message} open_feedback={this.state.open_feedback} message_type={'success'} close_feedback={(name) => this.setState({ open_feedback: false })} />

                <Feedback message={this.state.message} open_feedback={this.state.open_group_feedback} message_type={'success'} close_feedback={(name) => this.setState({ open_group_feedback: false })} />

                {/* Individual Status Modal */}
                <TransitionablePortal open={this.state.show_modal} transition={{ animation: 'fly down', duration: 500 }}>
                    <Segment inverted={this.props.dark} raised style={{ position: 'fixed', top: 40, right: 80, width: 350, zIndex: 1000 }}>
                        <Header
                        
                            as='h3'
                            icon={
                                this.state.status === 'demote' ? 'arrow left' : this.state.status === 'delete' ? 'trash' : 'arrow right'
                            }
                            content={
                                this.state.status === 'demote' ? 'Move Student to the Class Before ? ' :
                                    this.state.status === 'delete' ? 'Would you like to delete Student ?' : 'Promote Student to the Next Class ?'
                            }

                            subheader={
                                <>
                                    <br />
                                    <br />
                                    <Button color='green' size='tiny' circular onClick={() => this.setState({ show_modal: false })}>Cancel</Button> &nbsp; &nbsp;
                                <Button color='red' size='tiny' circular onClick={() => {
                                        if (this.state.status === 'demote') {

                                            this.demoteStudent(this.state.preview_id)

                                        } else if (this.state.status === 'promote') {
                                            this.promoteStudent(this.state.preview._id)

                                        } else if (this.state.status === 'delete') {
                                            this.renderProfileInactive(this.state.preview._id)
                                        }
                                    }}>Confirm</Button>
                                </>
                            }

                        />
                    </Segment>
                </TransitionablePortal>
                            {/*Grouped State Modal. */}
                <TransitionablePortal open={this.state.show_group_modal} transition={{ animation: 'fly down', duration: 500 }}>
                    <Segment inverted={this.props.dark}  raised style={{ position: 'fixed', top: 40, right: 80, width: 380, zIndex: 1000 }}>
                        <Header
                        
                            as='h3'
                            icon={
                                this.state.status === 'demote' ? 'arrow left' : this.state.status === 'delete' ? 'trash' : 'arrow right'
                            }
                            content={
                                this.state.status === 'demote' ? 'Move Selected Students to the Class Before ? ' :
                                    this.state.status === 'delete' ? 'Would you like to delete selected student ?' : 'Promote selected student to the Next Class ?'
                            }

                            subheader={
                                <>
                                    <br />
                                    <br />
                                    <Button color='green' size='tiny' circular onClick={() => this.setState({ show_group_modal: false })}>Cancel</Button> &nbsp; &nbsp;
                                <Button color='red' size='tiny' circular onClick={(e) => {
                                        if (this.state.status === 'demote') {

                                           this.setMassDemotion()

                                        } else if (this.state.status === 'promote') {
                                            this.setMassPromotion()

                                        } else if (this.state.status === 'delete') {
                                             this.setMassInActive()
                                        }
                                    }}>Confirm</Button>
                                </>
                            }

                        />
                    </Segment>
                </TransitionablePortal>
     
                <Grid.Column computer={12} mobile={16}>
                    {this.state.checked ?
                        <Segment raised inverted={this.props.dark}>
                            <Grid>
                                {this.state.loading ? <Loader active inline='centered' /> :
                                    this.props.students.map((stud, idx) => (
                                        <Grid.Column key={idx} computer={3} mobile={8}>
                                            <Card color={'teal'} onClick={(event) => { this.setState({ preview: stud, status: '', show_modal: false }) }}>
                                                <Image src={stud.profile_image} wrapped ui={false} size='tiny' />
                                                <Card.Content>
                                                    <Card.Header textAlign={'center'}>{stud.last_name}</Card.Header>
                                                    <Card.Meta textAlign={'center'}>{stud.date_of_birth}</Card.Meta>
                                                </Card.Content>
                                            </Card>
                                        </Grid.Column>
                                    ))}
                            </Grid>

                        </Segment> :
                        <Segment raised >
                            <Table celled inverted={this.props.dark}>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>Profile</Table.HeaderCell>
                                        <Table.HeaderCell colSpan={2}>Name</Table.HeaderCell>
                                        <Table.HeaderCell >Date of Birth</Table.HeaderCell>
                                        <Table.HeaderCell>Fees Status</Table.HeaderCell>
                                        <Table.HeaderCell>P. Contact</Table.HeaderCell>
                                        <Table.HeaderCell> Mark </Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {this.state.loading ? <Loader active inline='centered' /> :
                                        this.props.students.map((std, idx) => (
                                            <Table.Row key={idx} unstackable>
                                                <Table.Cell  onClick={(event) => { this.setState({ preview: std, status: '', show_modal: false }) }}> <Image avatar src={std.profile_image} /> </Table.Cell>
                                                <Table.Cell colSpan={2}>{std.last_name + " " + std.other_names}</Table.Cell>
                                                <Table.Cell>{std.date_of_birth}</Table.Cell>
                                                <Table.Cell>{this.getPaymentStatus(std.student_id)}</Table.Cell>
                                                <Table.Cell>{std.guardian_phone}</Table.Cell>
                                                <Table.Cell><Checkbox onClick={(e, data) => this.makeSelections(data.checked,std._id, std.student_id)} slider  /></Table.Cell>
                                            </Table.Row>
                                        ))}
                                        
                                </Table.Body>
                                <Table.Footer>
                                <Table.Row>                  
                                         <Table.Cell>
                                                <Button  onClick={(e) => this.setState({status:'demote',show_group_modal:true})} disabled circular icon color="orange"><Icon name="hand point left"/></Button>
                                            </Table.Cell>

                                            <Table.Cell>
                                                <Button onClick={(e) => this.setState({status:'promote',show_group_modal:true})} disabled circular icon color="green"><Icon name="hand point right"/></Button>
                                            </Table.Cell>

                                            <Table.Cell>
                                                <Button onClick={(e) => this.setState({status:'delete',show_group_modal:true})}  disabled circular icon color="red"><Icon name="trash"/></Button>
                                            </Table.Cell>

                                        </Table.Row>
                                </Table.Footer>
                            </Table>
                        </Segment>}
                </Grid.Column>

                <Grid.Column computer={4} className='hom'>
                    <Grid.Row>
                        <Grid.Column computer={16}>
                            <Header as="h2" inverted={this.props.dark}>
                                Current View: {this.state.checked ? " Tiles " : "Tables"}
                                <Header.Subheader>
                                    <Checkbox slider checked={this.state.checked} onChange={(e) => this.setState({ checked: !this.state.checked })} />
                                </Header.Subheader>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Divider horizontal>-</Divider>
                    <Grid.Row>
                        <Grid.Column computer={16}>
                            <Segment raised style={{ padding: 0 }} inverted={this.props.dark} >
                                {this.state.preview === null ?
                                <Statistic className="cLabel" inverted={this.props.dark}>
                                    <Statistic.Value>{this.props.students.length}</Statistic.Value>
                                    <Statistic.Label>Students - {this.props.profile.class_assigned}</Statistic.Label>
                                </Statistic>
                                    
                                    :
                                    <Card style={{ borderRadius: 0 }} >
                                        <Image src={this.state.preview.profile_image} />
                                        <Card.Content>
                                            <Card.Header textAlign='center'>{this.state.preview.last_name + " " + this.state.preview.other_names}</Card.Header>
                                            <Card.Meta>Admitted on: {this.state.preview.date_registered}</Card.Meta>
                                            <Card.Description>
                                                <Table singleLine inverted={this.props.dark}>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell>Student ID</Table.Cell>
                                                            <Table.Cell>{this.state.preview.student_id}</Table.Cell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.Cell>Date of Birth</Table.Cell>
                                                            <Table.Cell>{this.state.preview.date_of_birth}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>Religion</Table.Cell>
                                                            <Table.Cell>{this.state.preview.religion}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>Mother</Table.Cell>
                                                            <Table.Cell>{this.state.preview.name_of_mom}</Table.Cell>
                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.Cell>Father</Table.Cell>
                                                            <Table.Cell>{this.state.preview.name_of_dad}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>Location</Table.Cell>
                                                            <Table.Cell>{this.state.preview.place_of_stay}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell>Contact</Table.Cell>
                                                            <Table.Cell>{this.state.preview.guardian_phone}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell colSpan='2'>
                                                                <Button.Group icon widths={3}>
                                                                    <Button disabled color='google plus' onClick={this.confirmProcess('delete')}>
                                                                        <Icon name='trash' />
                                                                    </Button>
                                                                    <Button color='red' disabled onClick={this.confirmProcess('demote')}>
                                                                        <Icon name='arrow left' />
                                                                    </Button>
                                                                    <Button color='green' disabled onClick={this.confirmProcess('promote')}>
                                                                        <Icon name='arrow right' />
                                                                    </Button>
                                                                </Button.Group>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>

                                }
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>

                </Grid.Column>
            </Grid>
        );
    }
}

export default Division;