import React, { useState, useEffect } from 'react'
import { Grid, List, Header, Segment, Form, Button, Confirm, Icon, Image, Card, Input } from 'semantic-ui-react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { all_subjects } from '../_calls'
import { db, storage } from '../services'
import Feedback from '../_calls/feedback'
import _ from 'lodash'

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'image': [] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean']                                         // remove formatting button
];

export default function Assignments({ assign, profile, students }) {


    const [content, setContent] = useState(null)
    const [subject, setSubject] = useState(all_subjects[0].value)
    const [end_date, setDate] = useState(null)
    const [code, setCode] = useState('')
    const [s_code, setScode] = useState('')
    const [confirm_add, setAddConfirm] = useState(false)
    const [dconfirm, setDeleteConfirm] = useState(false)
    const [message, setMessage] = useState(false)
    const [opn, setOpn] = useState(false)
    const [cc, setCC] = useState('')
    const [insmod, setInsMod] = useState(false);
    const [turnIns, setTurn] = useState([])
    const [assignFile, setAssignFile] = useState(null)

    const storageRef = storage.child('class_assignments/' + profile.class_assigned + "/" + subject + "-" + new Date().getDay() + "-" +  new Date().getFullYear())

    useEffect(() => {
        db.collection('assignment_submission').onSnapshot(qss => {
            let submits = []
            qss.forEach(doc => {
                submits.push({ ...doc.data(), _id: doc.id })
            })
            setTurn(submits); submits = []
        })

    }, [])

    async function postAssignment() {
        //check if code exists.

        let exists = _.find(assign, (asd) => { return asd.ass_code === code })

        try {
                if (assignFile !== null && exists === undefined) {
                await storageRef.put(assignFile).then(resp => {
                    storageRef.getDownloadURL().then(url => {
                        db.collection('class_assignments').add({
                            date_due: end_date,
                            file_link: url,
                            date_sent: new Date().toLocaleDateString(),
                            subject: subject,
                            content: content,
                            approved:false,
                            class_target: profile.class_assigned,
                            ass_code: code,
                            sent_by: profile.staff_id
                        }).then(resp => {
                            setContent(null); setInsMod(false); setCode(''); setOpn(true); setMessage('Assignment Submission');
                        }).catch(err => {
                            alert(err.message)
                        })
                    }).catch(err => {
                        alert(err.message)
                    })
                }).catch(err => {
                    alert(err.message)
                })

            }else if (assignFile === null) {
                await db.collection('class_assignments').add({
                    date_due: end_date,
                    date_sent: new Date().toLocaleDateString(),
                    subject: subject,
                    approved:false,
                    content: content,
                    class_target: profile.class_assigned,
                    ass_code: code,
                    sent_by: profile.staff_id
                }).then(resp => {
                    setContent(null); setInsMod(false); setCode(''); setOpn(true); setMessage('Assigment Submission');
                }).catch(err => {
                    alert(err.message)
                })
            } else {
                
                alert("Change Assignment Code")
            }
        }catch (err) {
            alert(err)
        }


    }

    const assgn = () => {
        let assigns = []
        for (var a in assign) {
            assigns.push({ value: assign[a].ass_code, text: assign[a].subject + " - " + assign[a].date_sent })
        }
        return assigns
    }

    const delete_ass = event => {
        db.collection('class_assignments').doc(s_code).delete().then(resp => {

        }).catch(err => alert(err.message))
    }

    const getAssignmentFile = event => {
        let file = event.target.files[0]
        let Ftype = file.type
        let Fsize = file.size / 1024;

        //check file type 
        if (Ftype !== 'application/pdf') {
            alert("Please, Upload a PDF instead!")
        } else {
            if (Fsize > 6000) {
                alert("Maximum file size accepted is five (5) MB")
            } else {
                setAssignFile(file)
            }
        }
    }

    const getSubmission = (student_id) => {
        let status = _.find(turnIns, (asd) => { return asd.student_id === student_id && asd.ass_code === cc })
        if (status === undefined) {
            return <> <Icon name="cancel" color="red" /> Not Submitted </>
        } else {
            return <> <Icon name="checkmark" color="green" />Submitted on {status.date_sent} </>
        }

    }
    return (
        <Grid columns={2} padded="horizontally">
            <Feedback message={message} open_feedback={opn} close_feedback={(e) => setOpn(false)} message_type="success" />
            <Confirm
                closeOnDimmerClick={false}
                size="mini"
                open={confirm_add}
                header='Confirm Assignment Post!'
                content="This action will publish assignments to your students. Continue ?"
                confirmButton={<Button circular size="mini" color="green">Yes, Continue </Button>}
                cancelButton={<Button color="red" circular size="mini">No, Don't </Button>}
                onCancel={() => setAddConfirm(false)}
                onConfirm={() => { setAddConfirm(false); postAssignment() }}
            />

            <Confirm
                closeOnDimmerClick={false}
                size="mini"
                open={dconfirm}
                header='Confirm Assignment Delete!'
                content="This action will delete already published Assignment. Continue ?"
                confirmButton={<Button circular size="mini" color="green">Yes, Continue </Button>}
                cancelButton={<Button color="red" circular size="mini">No, Don't </Button>}
                onCancel={() => setDeleteConfirm(false)}
                onConfirm={() => { setDeleteConfirm(false); delete_ass() }}
            />

            <Grid.Column computer={12} mobile={16}>
                <Segment raised>
                    <Header as="h1" content="Assignments" subheader={profile.class_admitted} />
                    <Grid columns={2}>
                        <Grid.Column computer={10} mobile={16}>
                            <Segment raised>
                                <Form size="mini" onSubmit={(e) => setAddConfirm(true)}>
                                    <Form.Group>
                                        <Form.Select required options={all_subjects} value={subject} label="Subject Name" onChange={(e, target) => setSubject(target.value)} />
                                        <Form.Input required value={end_date} type="date" min={new Date().toLocaleDateString()} onChange={(e) => setDate(e.target.value)} label="Submission Date" />
                                        <Form.Input required value={code} label="Subject Code" onChange={(e) => setCode(e.target.value)} />

                                    </Form.Group>

                                    <ReactQuill
                                        style={{ minHeight: '250px' }}
                                        modules={{
                                            toolbar: toolbarOptions
                                        }}
                                        placeholder="Type Instructions or Assignment Question"
                                        theme="snow" value={content}
                                        onChange={(content, delta, sources, editor) => {
                                            setContent(editor.getHTML()); setInsMod(true)
                                        }} />
                                    <br />
                                    <Input accept="*/pdf" onChange={getAssignmentFile} action={{ icon: 'send', color: 'green', disabled: !content || !subject || !end_date || !code }} type="file" /> <br /><br />

                                </Form>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column computer={6} mobile={16}>
                            <Segment raised>

                                {insmod ?
                                    <>
                                        <Header content="Preview" />
                                        <div dangerouslySetInnerHTML={{ __html: content }} />
                                    </> :
                                    _.filter(assign, (as) => as.class_target === profile.class_assigned && as.approved === true).map(idx => (
                                        <Card key={idx._id}>
                                            <Card.Content header={idx.subject} meta={"Date published:" + idx.date_sent} />
                                            <Card.Content description={<div dangerouslySetInnerHTML={{ __html: idx.content }} />} />
                                            <Card.Content extra>
                                                <Icon name='trash' color="red" onClick={() => { setScode(idx._id); setDeleteConfirm(true) }} /> &nbsp;&nbsp; &nbsp;
                                            <Icon name="calendar" color="green" />Date Due {idx.date_due}
                                                {idx.file_link === undefined ? null :
                                                    <a href={idx.file_link} target="_blank" rel="noopener noreferrer">File: <Icon color="teal" name="file pdf" /></a>}
                                            </Card.Content>
                                        </Card>
                                    ))
                                }
                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Segment>

            </Grid.Column>
            <Grid.Column computer={4} mobile={16}>
                <Segment raised>
                    <Header content="Submissions" subheader="Assignments Turned In" as="h2" />
                    <Form>
                        <Form.Select value={cc} onChange={(e, target) => setCC(target.value)} label="Assignments" options={assgn()} />
                    </Form>
                    <List relaxed animated verticalAlign="middle">
                        {students.map(std => (
                            <List.Item key={std.student_id}>
                                <Image avatar src={std.profile_image} />
                                <List.Content>
                                    <List.Header as="a">{std.last_name + " " + std.other_names}</List.Header>
                                    <List.Description as='a'>{getSubmission(std.student_id)}</List.Description>
                                </List.Content>
                            </List.Item>
                        ))}

                    </List>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}