import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {PrivateRoute, PublicRoute, Login} from './staff-ui/access'
import Main from './staff-ui/layout'
import {auth} from './staff-ui/services'
import 'semantic-ui-css/semantic.min.css'
import './App.css';



export default class App extends React.Component{
  constructor(){
    super()
    this.state={
      authenticated:false,
      loading:true
    }
  }

  componentDidMount(){
    auth().onAuthStateChanged((user) => {
      if(user){
        this.setState({authenticated:true, loading:false})
      }else{
        this.setState({authenticated:false, loading:false})
      }
    })
  }
  render(){
  
    return (
      <Router>
        <Switch>
          <PrivateRoute path='/' exact component={Main} authenticated={this.state.authenticated} /> 
          <PublicRoute path='/login' component={Login} authenticated={this.state.authenticated} />
        </Switch>
      </Router>
  
    )
  }
}
  



