import React from 'react'
import Chart from 'react-apexcharts'
import _ from 'lodash'
import { _courses } from '../_calls/data'


export default class DataGraph extends React.Component {
 
    getValue = (course, term) => {
        switch (course) {
            case _courses[0].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.mts >= this.props.passMark }).length

            case _courses[2].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.sts >= this.props.passMark }).length

            case _courses[1].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.ets >= this.props.passMark }).length


            case _courses[3].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.ssts >= this.props.passMark }).length

            case _courses[8].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.owts >= this.props.passMark }).length

            case _courses[10].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.fts >= this.props.passMark }).length

            case _courses[7].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.hts >= this.props.passMark }).length

            case _courses[4].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.ictts >= this.props.passMark }).length

            case _courses[9].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.fnts >= this.props.passMark }).length
                
            case _courses[5].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.rmts >= this.props.passMark }).length
            
            case _courses[6].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.bdtts >= this.props.passMark }).length

            case _courses[12].value:
                return _.filter(this.props._history, (cr) => { return cr.term === term && cr.ctts >= this.props.passMark }).length
            
            case _courses[13].value: //p and h
            return _.filter(this.props._history, (cr) => { return cr.term === term && cr.phts >= this.props.passMark }).length

            case _courses[11].value: //creative arts
            return _.filter(this.props._history, (cr) => { return cr.term === term && cr.crats >= this.props.passMark }).length

                default:
                return 0
        }

    }

    getCoursePrimaryDetails = () => {

        const mF = this.getValue(_courses[0].value, 'First Term')
        const mS = this.getValue(_courses[0].value, 'Second Term')
        const mT = this.getValue(_courses[0].value, 'Third Term')

        const sF = this.getValue(_courses[2].value, 'First Term')
        const sS = this.getValue(_courses[2].value, 'Second Term')
        const sT = this.getValue(_courses[2].value, 'Third Term')


        const rmF = this.getValue(_courses[5].value, 'First Term')
        const rmS = this.getValue(_courses[5].value, 'Second Term')
        const rmT = this.getValue(_courses[5].value, 'Third Term')

        const owF = this.getValue(_courses[8].value, 'First Term')
        const owS = this.getValue(_courses[8].value, 'Second Term')
        const owT = this.getValue(_courses[8].value, 'Third Term')


        const eF = this.getValue(_courses[1].value, 'First Term')
        const eS = this.getValue(_courses[1].value, 'Second Term')
        const eT = this.getValue(_courses[1].value, 'Third Term')

        const hF = this.getValue(_courses[7].value, 'First Term')
        const hS = this.getValue(_courses[7].value, 'Second Term')
        const hT = this.getValue(_courses[7].value, 'Third Term')


        const fF = this.getValue(_courses[10].value, 'First Term')
        const fS = this.getValue(_courses[10].value, 'Second Term')
        const fT = this.getValue(_courses[10].value, 'Third Term')

        const cF = this.getValue(_courses[4].value, 'First Term')
        const cS = this.getValue(_courses[4].value, 'Second Term')
        const cT = this.getValue(_courses[4].value, 'Third Term')

        const fnF = this.getValue(_courses[9].value, 'First Term')
        const fnS = this.getValue(_courses[9].value, 'Second Term')
        const fnT = this.getValue(_courses[9].value, 'Third Term')

        const crF = this.getValue(_courses[11], 'First Term')
        const crS = this.getValue(_courses[11], 'Second Term')
        const crT = this.getValue(_courses[11], 'Third Term')


        return [
            {
                name: _courses[0].text,
                data: [mF, mS, mT]
            },
            {
                name: _courses[2].text,
                data: [sF, sS, sT]
            },
            {
                name: _courses[1].text,
                data: [eF, eS, eT]
            },
            {
                name: _courses[7].text,
                data: [hF, hS, hT]
            },
            {
                name: _courses[9].text,
                data: [fF, fS, fT]
            },
            {
                name:_courses[4].text,
                data: [cF, cS, cT]
            },
            {
                name:_courses[8].text,
                data:[owF, owS, owT]
            },
            {
                name: _courses[10].text,
                data: [fnF, fnS, fnT]
            },
        
            {
                name: _courses[11].text,
                data: [crF, crS, crT]
            },
            {
                name:_courses[5].text,
                data:[rmF, rmS, rmT]
            }
        ]


    }

    getSemesterCourseDetails = () => {
        const mF = this.getValue(_courses[0].value, 'First Semester')
        const mS = this.getValue(_courses[0].value, 'Second Semester')

        
        const eF = this.getValue(_courses[1].value, 'First Semester')
        const eS = this.getValue(_courses[1].value, 'Second Semester')
        

        const sF = this.getValue(_courses[2].value, 'First Semester')
        const sS = this.getValue(_courses[2].value, 'Second Semester')
        

        const soF = this.getValue(_courses[3].value, 'First Semester')
        const soS = this.getValue(_courses[3].value, 'Second Semester')

        const icF = this.getValue(_courses[4].value, 'First Semester')
        const icS = this.getValue(_courses[4].value, 'Second Semester')

        const rmF = this.getValue(_courses[5].value, 'First Semester')
        const rmS = this.getValue(_courses[5].value, 'Second Semester')
        

        const bdF = this.getValue(_courses[6].value, 'First Semester')
        const bdS = this.getValue(_courses[6].value, 'Second Semester')
        

        const fnF = this.getValue(_courses[9].value, 'First Semester')
        const fnS = this.getValue(_courses[9].value, 'Second Semester')

  
        

        const fF = this.getValue(_courses[10].value, 'First Semester')
        const fS = this.getValue(_courses[10].value, 'Second Semester')
        

        const ctF = this.getValue(_courses[12].value, 'First Semester')
        const ctS = this.getValue(_courses[12].value, 'Second Semester')

        const phF = this.getValue(_courses[13].value, 'First Semester')
        const phS = this.getValue(_courses[13].value, 'Second Semester')

        
        
        return [
            {
                name: _courses[0].text,
                data: [mF, mS]
            },
            {
                name: _courses[2].text,
                data: [sF, sS]
            },
            {
                name: _courses[3].text,
                data: [soF, soS,]
            },
            {
                name: _courses[1].text,
                data: [eF, eS]
            },
            {
                name: _courses[5].text,
                data: [rmF, rmS]
            },
            {
                name: _courses[10].text,
                data: [fF, fS]
            },
            {
                name: _courses[9].text,
                data: [fnF, fnS]
            },
        
            {
                name: _courses[6].text,
                data: [bdF, bdS]
            },
            {
                name:_courses[12].text,
                data:[ctF, ctS]
            },
            {
                name: _courses[13].text,
                data:[phF, phS]
            },
            {
                name:_courses[4].text,
                data:[icF, icS]
            }
        ]
        
    }

    checkTerm = () => {
        return this.props.term.length >= 14
    }

    render() {
        const series = this.checkTerm() ? this.getSemesterCourseDetails() : this.getCoursePrimaryDetails()
        return (
            <Chart type="line" series={series}
              options={{
                chart: {
                    type: 'line',
                    height: 328,
                    zoom: {
                        enabled: false
                    },
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 2,
                        blur: 4,
                        opacity: 1,
                    },

                }, stroke: {
                    curve: 'smooth',
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                title: {
                    text: 'Course Statistics for ' + this.props.current_class,
                    align: 'left',
                    style: {
                        fontSize: '18px',
                        fontFamily: 'Sen'
                    },
                    offsetY:0,
                    offsetX: 7
                },
                subtitle: {
                    text: 'All Terms / Semesters',
                    offsetY: 20,
                    offsetX: 7,
                    style: {
                        fontSize: '14px',
                        fontFamily: 'Sen'
                    },
                },
                markers: {
                    size: 6,
                    strokeWidth: 0,
                    hover: {
                        size: 9
                    },
                    style: {
                        fontSize: '12px',
                        fontFamily: 'Sen'
                    },
                },
                grid: {
                    show: true,
                    padding: {
                        bottom: 0
                    }
                },
                labels: this.checkTerm() ? ['First Semester', 'Second Semester'] : ['First Term', 'Second Term', 'Third Term'],
                xaxis: {
                    style: {
                        fontFamily: 'Sen'
                    },
                    tooltip: {
                        enabled: true,
                        style: {
                            fontFamily: 'Sen !important'
                        },
                    }
                },
                legend: {
                    style: {
                        fontFamily: 'Sen !important'
                    },
                    position: 'top',
                    horizontalAlign: 'right',
                    offsetY: -20,
                }
            }}
              />
        )
    }
}