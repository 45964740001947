import React from 'react'
import { Segment, Grid, Container, Header, Table, Form, Select, Input, TextArea, Button, List, Statistic, Item } from 'semantic-ui-react'
import DataGraph from '../graph'
import _ from 'lodash'
import { db, auth } from '../services'
import Feedback from '../_calls/feedback'
import { _courses as courses } from '../_calls/data'
import placeImage from '../images/placeholder-image.png'

export default class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mtitle: '',
            mcontent: '',
            noteContent: '',
            noteTitle: '',
            openF: false,
            message: '',
            message_type: '',
            cNote: {},
            note_id: '',
            current_time: '',
            current_course: courses[0].value
        }
    }

    addNewNote = event => {
        event.preventDefault()
        db.collection('staff_notes').add({
            title: this.state.noteTitle,
            content: this.state.noteContent,
            author: this.props.profile.staff_id,
            date_: new Date().toLocaleDateString(),
            time_: new Date().toLocaleTimeString()
        }).then(resp => {
            this.setState({ noteContent: "", noteTitle: "", message: 'Note Addition', message_type: 'success', openF: true })
        }).catch(err => {
            this.setState({ message: err.message, message_type: 'error', openF: true })
        })

    }

    sendNotification = event => {
        event.preventDefault()
        db.collection('notifications').add({
            subject: this.state.mtitle,
            content: this.state.mcontent,
            from: this.props.profile.last_name + " " + this.props.profile.first_name,
            time: new Date().toLocaleTimeString(),
            date: new Date().toLocaleDateString(),
            profile_image: auth().currentUser.photoURL,
            author_id: this.props.profile.email
        }).then(resp => {
            this.setState({ mcontent: "", mtitle: "", message: 'Message Sent', message_type: 'success', openF: true })
        }).catch(err => {
            this.setState({ message: err.message, message_type: 'error', openF: true })
        })
    }

    isSemester = (cls) => {
        
       return cls === undefined ? false : cls.charAt(0) === 'J' || cls === 'Basic 7' || cls === 'Basic 8' || cls === 'Basic 9' || cls === 'Basic 10' 
    }

    getPercentages = (course, grade) => {

        switch (course) {
            case courses[0].value:
                return _.filter(this.props._history, (grd) => { return grd.mgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case courses[1].value:
                return _.filter(this.props._history, (grd) => { return grd.egr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length


            case courses[2].value:
                return _.filter(this.props._history, (grd) => { return grd.sgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length


            case courses[3].value:
                return _.filter(this.props._history, (grd) => { return grd.ssgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length


            case courses[4].value:
                return _.filter(this.props._history, (grd) => { return grd.ictgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case courses[5].value:
                return _.filter(this.props._history, (grd) => { return grd.rmgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case courses[7].value:
                return _.filter(this.props._history, (grd) => { return grd.hgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case courses[8].value:
                    return _.filter(this.props._history, (grd) => { return grd.owgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length
    
            case courses[9].value:
                return _.filter(this.props._history, (grd) => { return grd.fngr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case courses[10].value:
                return _.filter(this.props._history, (grd) => { return grd.fgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case courses[11].value:
                return _.filter(this.props._history, (grd) => { return grd.cragr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case courses[12].value:
                    return _.filter(this.props._history, (grd) => { return grd.ctgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length
            
            case courses[13].value:
                        return _.filter(this.props._history, (grd) => { return grd.phgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length
        
            default:
                return _.filter(this.props._history, (grd) => { return grd.mgr === grade && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() }).length


        }

    }
 
    getPasses = (course) => {
        switch (course) {
            case "Mathematics":
                return _.filter(this.props._history, (grd) => { return grd.mts >= parseInt(this.props.passMark) && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case "English":
                return _.filter(this.props._history, (grd) => { return grd.ets >= parseInt(this.props.passMark) && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case "Social":
                return _.filter(this.props._history, (grd) => { return grd.ssts >= parseInt(this.props.passMark) && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length


            case "Science":
                return _.filter(this.props._history, (grd) => { return grd.sts >= parseInt(this.props.passMark) && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            case "Our World":
                return _.filter(this.props._history, (grd) => { return grd.owts >= parseInt(this.props.passMark) && new Date(grd.date_processed).getFullYear() === new Date().getFullYear() && grd.term === this.props.term }).length

            default:
                return 0
        }
    }

    getPopulationStat = () => {
        let total = this.props.students.length
        let males = _.filter(this.props.students, (std) => { return std.gender === 'Male' }).length
        let females = _.filter(this.props.students, (std) => { return std.gender === 'Female' }).length

        return [total, males, females]
    }

    isPreSchool = () => {
        const class_assigned = this.props.profile.class_assigned
        return class_assigned === undefined ? false : class_assigned.charAt(0) === "N" || class_assigned.charAt(0) === 'K'
    }


    render() {


        return (
            <Grid className='open-grids'>
                <Feedback open_feedback={this.state.openF} close_feedback={(value) => this.setState({ openF: false })}
                    message={this.state.message} message_type={this.state.message_type} />
                <Grid.Row columns={3} verticalAlign="middle">
                    <Grid.Column mobile={16} computer={5}>
                        <Segment raised>
                            <Header content={this.props.profile.class_assigned ?? "Loading"} as="h3" />
                            <Statistic.Group size="small">
                                <Statistic color="green" value={this.getPopulationStat()[0]} label={'Total'} />
                                <Statistic color="teal" value={this.getPopulationStat()[1]} label={'Males'} />
                                <Statistic color="orange" value={this.getPopulationStat()[2]} label={'Females'} />

                            </Statistic.Group>
                        </Segment>

                    </Grid.Column>

                    <Grid.Column mobile={16} computer={5}>
                        <Segment raised>
                        <List relaxed verticalAlign={'middle'}>
                        <List.Item>
                            <List.Icon name='clock outline' color='orange'/>
                            <List.Content>Current Term: {this.props.term ?? "Loading"}</List.Content>
                            </List.Item>
                            <List.Item>
                            <List.Icon name='percent' color="teal" />
                            <List.Content>Pass Mark: {this.props.settings.pass_mark}</List.Content>
                            </List.Item>
                            <List.Item>
                            <List.Icon name='sliders horizontal' color="green" />
                            <List.Content>
                                No. Of Weeks: {this.props.settings.number_of_weeks}
                            </List.Content>
                            </List.Item>

                            <List.Item>
                            <List.Icon name='tasks' color="red" />
                            
                            <List.Content>
                                Next Term Begins : {this.props.settings.next_terms_begins}
                            </List.Content>

                            </List.Item>
                        </List>
                        </Segment>
                    </Grid.Column>

                    <Grid.Column mobile={16} computer={6}>
                        <Segment raised>
                            <Item.Group>
                                
                                <Item>
                                    <Item.Image size='tiny' src={this.props.profile.profile_image ?? placeImage} />
                                    <Item.Content>
                                        <Item.Header  style={{fontFamily:'Sen'}}>{`${this.props.profile.title ?? " "} ${this.props.profile.first_name ?? " "} ${this.props.profile.last_name ?? " "}`}</Item.Header>
                                        <Item.Meta>Date Joined: {new Date(this.props.profile.date_registered).toLocaleDateString()}</Item.Meta>
                                        <Item.Description>
                                        Class Assigned: {this.props.profile.class_assigned} <br />
                                        Marital Status: {this.props.profile.marital_status} <br />
                                        Date of Birth: {new Date(this.props.profile.date_of_birth).toLocaleDateString()}
                                        </Item.Description>
                                    </Item.Content>
                                </Item>
                            </Item.Group>
                        </Segment>
                    </Grid.Column>

                </Grid.Row>

                <Grid.Column computer={16} mobile={16}>
                    <Segment raised inverted={this.props.dark}>
                        <Grid padded>
                            <Grid.Column computer={12} mobile={16}>

                                <Grid>
                                    <Grid.Column computer={4} mobile={8}>
                                        <Segment raised textAlign="center" secondary>
                                            <Statistic  size="small" label={this.isPreSchool() ? 'Passes in Numeracy': 'Passes in Math'} value={this.getPasses('Mathematics')} />
                                        </Segment>

                                    </Grid.Column>

                                    <Grid.Column computer={4} mobile={8}>
                                        <Segment raised textAlign="center" secondary>
                                            <Statistic size="small" label='Passes in Science' value={this.getPasses('Science')} />

                                        </Segment>
                                    </Grid.Column>

                                    <Grid.Column computer={4} mobile={8}>
                                        <Segment raised textAlign="center" secondary>
                                            <Statistic size="small" label={this.isPreSchool() ? 'Passes in Literacy': 'Passes in English'}  value={this.getPasses('English')} />

                                        </Segment>
                                    </Grid.Column>

                                    <Grid.Column computer={4} mobile={8}>

                                        <Segment raised secondary>
                                            {this.isSemester(this.props.profile.class_assigned) ? 
                                            <Statistic size="small" label='Passes in Social' value={this.getPasses('Social')} />
                                            : 

                                            <Statistic size="small" label='Passes in OWOP' value={this.getPasses('Our World')} />
                                        }

                                        </Segment>

                                    </Grid.Column>
                                </Grid>
                                <br />
                                <Grid.Row>
                                    <Segment raised>
                                        <DataGraph term={this.props.term}  _history={this.props._history} passMark={this.props.passMark} current_class={this.props.profile.class_assigned} />
                                    </Segment>
                                </Grid.Row>
                            </Grid.Column>

                            <Grid.Column computer={4} mobile={16}>
                                <Segment raised>
                                    <Form>
                                        <Form.Field
                                            placeholder="Select Course"
                                            label="Select Course"
                                            control={Select}
                                            options={courses}
                                            onChange={(e, data) => this.setState({ current_course:data.value })}
                                        />

                                    </Form>
                                    <Table celled unstackable textAlign={'center'}>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan="2">Course Selected</Table.HeaderCell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.HeaderCell>Grade</Table.HeaderCell>
                                                <Table.HeaderCell>Total</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell>A</Table.Cell>
                                                <Table.Cell>{this.getPercentages(this.state.current_course, 'A')}</Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.Cell>B</Table.Cell>
                                                <Table.Cell>{this.getPercentages(this.state.current_course, 'B')}</Table.Cell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell>C</Table.Cell>
                                                <Table.Cell>{this.getPercentages(this.state.current_course, 'C')}</Table.Cell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell>D</Table.Cell>
                                                <Table.Cell>{this.getPercentages(this.state.current_course, 'D')}</Table.Cell>
                                            </Table.Row>


                                            <Table.Row>
                                                <Table.Cell>E</Table.Cell>
                                                <Table.Cell>{this.getPercentages(this.state.current_course, 'E')}</Table.Cell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell>F</Table.Cell>
                                                <Table.Cell>{this.getPercentages(this.state.current_course, 'F')}</Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table>
                                </Segment>
                                <Segment raised inverted={this.props.dark}>
                                    <Header as='h3' content="Send Notification" dividing inverted={this.props.dark} />
                                    <Form inverted={this.props.dark} onSubmit={this.sendNotification}>
                                        <Form.Field
                                            label="Message Title"
                                            control={Input}
                                            value={this.state.mtitle}
                                            required
                                            placeholder="Message Title"
                                            onChange={(e) => this.setState({ mtitle: e.target.value })}
                                            maxLength="50"
                                        />
                                        <Form.Field
                                            label="Message Content"
                                            value={this.state.mcontent}
                                            required
                                            control={TextArea}
                                            onChange={(e) => this.setState({ mcontent: e.target.value })}
                                            placeholder="Message Content"
                                        />
                                        <Button type='submit' icon='send' circular color="orange" disabled={!this.state.mtitle || !this.state.mcontent} />
                                    </Form>

                                </Segment>
                            </Grid.Column>
                        </Grid>
                    </Segment>
                </Grid.Column>


                <Grid.Column computer={16} mobile={16}>
                    <Grid>
                        <Grid.Column computer={6} mobile={16}>
                            <Segment raised inverted={this.props.dark}>
                                <Header icon='edit' content="Add New Note" subheader="Todo List Create" inverted={this.props.dark} />
                                <Form onSubmit={this.addNewNote} inverted={this.props.dark}>
                                    <Form.Field
                                        required
                                        control={Input}
                                        value={this.state.noteTitle}
                                        onChange={(e) => this.setState({ noteTitle: e.target.value })}
                                        label="Note Title"
                                        maxLength={60}
                                    />

                                    <Form.Field
                                        required
                                        control={TextArea}
                                        value={this.state.noteContent}
                                        onChange={(e) => this.setState({ noteContent: e.target.value })}
                                        label="Content"
                                    />
                                    <Button icon='add' color='green' disabled={!this.state.noteContent || !this.state.noteTitle}
                                        circular type='submit' />
                                </Form>
                            </Segment>
                        </Grid.Column>

                        <Grid.Column computer={10} mobile={16}>
                            <Segment raised inverted={this.props.dark}>
                                <Grid>
                                    <Grid.Column computer={7} mobile={16}>
                                        <Segment raised inverted={this.props.dark}>
                                            <Header as='h4' inverted={this.props.dark}>Saved Notes</Header>
                                            <List inverted={this.props.dark} selection animated divided relaxed>
                                                {this.props.notes.map(nt => (
                                                    <List.Item key={nt._id} onClick={(e) => this.setState({ note_id: nt._id, cNote: nt })}>
                                                        <List.Icon color={this.state.cNote._id === nt._id ? 'green' : 'orange'}
                                                            name={this.state.cNote._id === nt._id ? 'bookmark' : 'bookmark outline'}
                                                            size='large' verticalAlign='middle' />
                                                        <List.Content>
                                                            <List.Header as='a'>{nt.title}</List.Header>
                                                            <List.Description>Created on: {nt.date_}</List.Description>
                                                        </List.Content>
                                                    </List.Item>
                                                ))}
                                            </List>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column computer={9} mobile={16}>
                                        <Segment raised inverted={this.props.dark}>
                                            {this.state.note_id === '' ?
                                                <Header as='h1' inverted={this.props.dark} textAlign='center' content="No Note Selected !" /> :
                                                <Container text fluid>
                                                    <Header as='h4' inverted={this.props.dark} subheader={"Created on: " + this.state.cNote.date_ + " at " + this.state.cNote.time_}
                                                        content={this.state.cNote.title.toUpperCase()} />
                                                    <p>{this.state.cNote.content}</p>
                                                    <hr />
                                                    <Button circular onClick={(e) => {
                                                        db.collection('staff_notes').doc(this.state.note_id).delete().then(resp => {
                                                            this.setState({ note_id: '', cNote: {} })
                                                        })
                                                    }}
                                                        icon='trash alternate outline'
                                                        size="tiny" color="red" />
                                                </Container>

                                            }
                                        </Segment>
                                    </Grid.Column>
                                </Grid>

                            </Segment>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>


            </Grid >
        )
    }
}