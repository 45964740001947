import React, { Component } from "react";
import { Grid, Segment, List, Image, Icon, Header } from "semantic-ui-react";
import _ from "lodash";
import ExaminationTable from "../exams_sheets";

class Examination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _student: {},
      student_selected: false,
      class_category: "upper_primary",
      term:props.sett.current_term,
      is_processed: "",
      report_id: "not_available",
    };
  }

  getReportStatus = (_id) => {
    if (this.props.students !== null) {
      let status = _.find(this.props._history, (rpt) => {
        return (
          rpt.student_id === _id &&
          rpt.term === this.state.term &&
          rpt.class_admitted === this.props.profile.class_assigned
        );
      });

      if (status === undefined) {
        return "not-processed";
      } else if (
        status.academic_year === this.props.sett.academic_year
      ) {
        return "processed";
      } else {
        console.log(status);
      }
    }
  };

  getReportId = (_id) => {
    let term = this.props.sett.current_term;
    if (this.props.students !== null) {
      let status = _.find(this.props._history, (rpt) => {
        return (
          rpt.student_id === _id &&
          rpt.term === term &&
          rpt.class_admitted === this.props.profile.class_assigned
        );
      });

      if (status === undefined) {
        this.setState({ report_id: "not_available" });
      } else {
        this.setState({ report_id: status._id });
      }
    }
  };



  render() {
    return (
      <Grid className="open-grids">
        <Grid.Column computer={4} mobile={16}>
          <Grid.Row>
            <Grid.Column computer={16} mobile={16}>
              <Header
                content="Examination Report Processing"
                as="h3"
                subheader={this.props.profile.class_assigned}
              />
              <Segment
                raised
                style={{ maxHeight: "100vh", overflowY: "scroll" }}
                inverted={this.props.dark}
              >
                <List relaxed divided inverted={this.props.dark}>
                  {this.props.students.map((std, idx) => (
                    <List.Item
                      key={idx}
                      onClick={(e) =>
                        this.setState(
                          {
                            student_selected: true,
                            _student: std,
                            class_category: std.class_category,
                            is_processed: this.getReportStatus(std.student_id),
                          },
                          () => this.getReportId(std.student_id)
                        )
                      }
                    >
                      <Image avatar src={std.profile_image} />
                      <List.Content>
                        <List.Header
                          as="h4"
                          className="senize"
                          style={{ cursor: "pointer" }}
                        >
                          {std.last_name + " " + std.other_names}
                        </List.Header>
                        <List.Description>
                          {this.getReportStatus(std.student_id) ===
                          "not-processed" ? (
                            <>
                              <Icon name="cancel" color="red" /> Not Processed
                            </>
                          ) : this.getReportStatus(std.student_id) ===
                            "processed" ? (
                            <>
                              <Icon name="checkmark" color="green" /> Processed
                            </>
                          ) : (
                            ""
                          )}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  ))}
                </List>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>

        <Grid.Column computer={12} mobile={16}>
          <Segment raised>
            <ExaminationTable
              is_processed={this.state.is_processed}
              report_id={this.state.report_id}
              current_sheet={this.state.class_category}
              term={this.props.sett.current_term}
              current_student={this.state._student}
              sett={this.props.sett}
              dark_mode={this.props.dark}
              attendance={this.props.attendance}
              _new={this.state.student_selected}
              report_history={this.props._history}
              roll={this.props.students.length}
            />
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Examination;
