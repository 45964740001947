import React, { Component } from "react";
import _ from "lodash";
import {
  Grid,
  Table,
  Icon,
  Select,
  Checkbox,
  Form,
  Button,
  Segment,
  Confirm,
} from "semantic-ui-react";
import { _days, weeks } from "../_calls/data";
import { db } from "../services";

import Feedback from "../_calls/feedback";

class Attendance extends Component {
  constructor() {
    super();
    this.state = {
      day: _days[new Date().getDay()].value,
      week: "No Week Selected!",
      current_class: [],
      _attendance: [],
      message: "",
      is_success: false,
      message_type: "",
      confirm: false,
    };
  }
  prepareFields = (event) => {
    event.preventDefault();
    if (this.props.students === null) {
      alert("No available Data");
    } else {
      const cc_data = _.filter(this.props.students, (cd) => {
        return cd.class_admitted === this.props.profile.class_assigned;
      });
      this.setState({ current_class: cc_data });
      return _.sortBy(cc_data, (dt) => {
        return dt.last_name;
      });
    }
  };

  markAll = (checked) => {
    if (checked) {
      let temp = [];
      for (var std of this.state.current_class) {
        temp.push({
          student_id: std.student_id,
          term: this.props.sett.current_term,
          presence: true,
        });
        this.setState({ _attendance: temp });
      }
    } else {
      this.setState({ _attendance: [] });
    }
  };

  getAttendance = (status, index) => {
    const _status = _.find(this.state._attendance, (std) => {
      return std.student_id === index && std.presence !== status;
    });
    let term = this.props.sett.current_term;

    if (_status === undefined) {
      this.setState({
        _attendance: [
          ...this.state._attendance,
          {
            student_id: index,
            term: term,
            presence: status,
          },
        ],
      });
    } else {
      // remove existing and add new attendance record.
      const temp = this.state._attendance;
      //eslint-disable-next-line
      const removed = _.remove(temp, (std) => std.student_id === index);
      this.setState({ _attendance: temp });
    }
  };

  checkStatus = (id) => {
    const isHere = _.find(
      this.state._attendance,
      (std) => std.student_id === id && std.presence === true
    );
    return isHere === undefined ? false : true;
  };

  reportToday = (event) => {
    let term = this.props.sett.current_term;
    const todays_report = {
      date: new Date().toLocaleString(),
      week_name: this.state.week,
      class: this.props.profile.class_assigned,
      day: this.state.day,
      term: term,
      academic_year:this.props.sett.academic_year,
      year: new Date().getFullYear(),
      processed_by:
        this.props.profile.first_name + " " + this.props.profile.last_name,
      time_processed: new Date().toLocaleTimeString(),
      data: this.state._attendance,
    };

    let _marked = _.find(this.props.attendance, (pd) => {
      return (
        pd.day === this.state.day &&
        pd.term === term &&
        pd.year === new Date().getFullYear() &&
        pd.week_name === this.state.week &&
        pd.class === this.props.profile.class_assigned
      );
    });
    if (_marked === undefined) {
      db.collection("attendance")
        .add(todays_report)
        .then((resp) =>
          this.setState({
            is_success: true,
            message: "Reporting Processing",
            message_type: "success",
            _attendance: [],
          })
        )
        .catch((err) => alert(err.message));
    } else {
      db.collection("attendance")
        .doc(_marked._id)
        .update(todays_report)
        .then(() => {
          this.setState({
            is_success: true,
            message_type: "error",
            message: "Processing Successful",
          });
        })
        .catch((err) =>
          this.setState({
            message_type: "error",
            message: err.message,
            _attendance: [],
          })
        );
    }
  };
  checkDayStatus = (index, day, week) => {
    let term = this.props.sett.current_term;

    if (this.props.attendance === null) {
      return false;
    } else {
      const _currentS = _.find(this.props.attendance, (atd) => {
        return (
          atd.term === term &&
          atd.day === day &&
          atd.week_name === week &&
          atd.year === new Date().getFullYear()
        );
      });

      if (_currentS === undefined) {
        return false;
      } else {
        let _status = _.filter(_currentS.data, (cs) => {
          return cs.student_id === index;
        })[0];

        if (_status === undefined) {
          // return false
          return <Icon name="cancel" color="red" />;
        } else if (_status.presence === true) {
          return <Icon name="checkmark" color="green" />;
        } else {
          return <Icon name="cancel" color="red" />;
        }
      }
    }
  };

  render() {

    return (
      <Grid className="open-grids">
        <Feedback
          message={this.state.message}
          open_feedback={this.state.is_success}
          close_feedback={(name) => this.setState({ is_success: false })}
          message_type={this.state.message_type}
        />
        <Confirm
          closeOnDimmerClick={false}
          size="mini"
          open={this.state.confirm}
          header="Process Attendance !"
          content="This action will process attendance to administrator"
          confirmButton={
            <Button circular size="mini" color="green">
              Yes, Continue{" "}
            </Button>
          }
          cancelButton={
            <Button color="red" circular size="mini">
              No, Don't
            </Button>
          }
          onCancel={() => this.setState({ confirm: false })}
          onConfirm={() => this.setState({ confirm: false }, this.reportToday)}
        />
        <Grid.Column computer={4} mobile={16}>
          <Grid.Row>
            <Grid.Column computer={16} mobile={16}>
              <Segment raised>
                <Form onSubmit={this.prepareFields} inverted={this.props.dark}>
                  {/* Depending on Strictness of the School Here! */}
                  <Form.Field
                    label="Current Day"
                    required
                    options={_days}
                    control={Select}
                    onChange={(e, target) =>
                      this.setState({ day: target.value })
                    }
                    value={this.state.day}
                  />

                  <Form.Field
                    label="Select Week"
                    onChange={(e, target) =>
                      this.setState({ week: target.value, _attendance: [] })
                    }
                    required
                    control={Select}
                    options={weeks}
                    placeholder="Select Week"
                  />
                  <Button
                    type="submit"
                    disabled={
                      !this.state.day ||
                      !this.props.profile.class_assigned ||
                      !this.state.week
                    }
                    compact
                    color="orange"
                  >
                    Continue
                  </Button>
                </Form>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>

        <Grid.Column computer={12} mobile={16}>
          <Segment raised>
            <Table
              celled
              unstackable
              inverted={this.props.dark}
              className="hide-on-mobile"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell rowSpan="2" colSpan="2">
                    Student Names
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="3" textAlign="center">
                    {this.state.week}
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="2" textAlign="center">
                    {this.props.profile.class_assigned}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Total: {this.props.students.length}
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Monday</Table.HeaderCell>
                  <Table.HeaderCell>Tuesday</Table.HeaderCell>
                  <Table.HeaderCell>Wednesday</Table.HeaderCell>
                  <Table.HeaderCell>Thursday</Table.HeaderCell>
                  <Table.HeaderCell>Friday</Table.HeaderCell>
                  <Table.HeaderCell>
                    <Checkbox
                      onClick={(e, data) => this.markAll(data.checked)}
                      label="Mark All"
                      checked={
                        this.props.students.length ===
                        this.state._attendance.length
                      }
                    />
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.students.map((cc) => (
                  <Table.Row key={cc.student_id}>
                    <Table.Cell colSpan="2">
                      {cc.last_name + "  " + cc.other_names}
                    </Table.Cell>
                    <Table.Cell>
                      {this.checkDayStatus(
                        cc.student_id,
                        "Monday",
                        this.state.week
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.checkDayStatus(
                        cc.student_id,
                        "Tuesday",
                        this.state.week
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.checkDayStatus(
                        cc.student_id,
                        "Wednesday",
                        this.state.week
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.checkDayStatus(
                        cc.student_id,
                        "Thursday",
                        this.state.week
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {this.checkDayStatus(
                        cc.student_id,
                        "Friday",
                        this.state.week
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        toggle
                        checked={this.checkStatus(cc.student_id)}
                        onClick={(e, target) =>
                          this.getAttendance(target.checked, cc.student_id)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="8">
                    <Button
                      onClick={() => this.setState({ confirm: true })}
                      positive
                      floated="right"
                      disabled={
                        new Date().getDay() === 0 ||
                        new Date().getDay() === 6 ||
                        this.state._attendance.length === 0
                      }
                    >
                      <Icon name="checkmark" /> Process Today's Attendance
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>

            {/* Mobile Attendance Table */}
            <Table unstackable celled fixed striped className="hide-on-desktop">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    Class: {this.props.profile.class_assigned}
                  </Table.HeaderCell>
                  <Table.HeaderCell colSpan="2">
                    <Checkbox
                      onClick={(e, data) => this.markAll(data.checked)}
                      label="Mark All"
                      checked={this.props.students.length ===
												this.state._attendance.length}
                    />
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Student</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>State</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.students.map((std) => (
                  <Table.Row key={std.student_id}>
                    <Table.Cell>
                      {std.last_name + " " + std.other_names}
                    </Table.Cell>
                    <Table.Cell>
                      {this.checkDayStatus(
                        std.student_id,
                        _days[new Date().getDay()].value,
                        this.state.week
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Checkbox
                        toggle
                        checked={this.checkStatus(std.student_id)}
                        onClick={(e, target) =>
                          this.getAttendance(target.checked, std.student_id)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="3">
                    <Button
                      onClick={() => this.setState({ confirm: true })}
                      disabled={
                        new Date().getDay() === 0 ||
                        new Date().getDay() === 6 ||
                        this.state._attendance.length === 0
                      }
                      compact
                    >
                      Mark Attendance
                    </Button>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Attendance;
