import  firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

// const firebase = require('firebase/app');
// require('firebase/<PACKAGE>');




  var firebaseConfig = {
    apiKey: "AIzaSyC8WTBZmibFhIaSAtnHYTvMZt0w3Oc5YfE",
    authDomain: "wris-gh.firebaseapp.com",
    projectId: "wris-gh",
    storageBucket: "wris-gh.appspot.com",
    messagingSenderId: "848188025331",
    appId: "1:848188025331:web:828a97a9973084aa5f4a86",
    measurementId: "G-F9WK9GMJMQ"
  }; 


firebase.initializeApp(firebaseConfig)
  firebase.firestore().settings({
    cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
  });
  
firebase.firestore().enablePersistence()

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

export const auth = firebase.auth

export const db  = firebase.firestore()

export const storage = firebase.storage().ref()