import React from "react";

import { db } from "../services";
import Feedback from "../_calls/feedback";
import _ from "lodash";
import { Table, Form, Button, Icon, Input, Confirm } from "semantic-ui-react";
import { remarks, attitudes, interests, conducts } from "../_calls/remarks";

const INITIAL_STATE = {
	mcs: 0,
	mes: 0,
	mts: 0,
	mgr: "F",
	mrm: "Buck Up",

	ecs: 0,
	ees: 0,
	ets: 0,
	egr: "F",
	erm: "Buck Up",

	fcs: 0,
	fes: 0,
	fts: 0,
	fgr: "F",
	frm: "Buck Up",

	fncs: 0,
	fnes: 0,
	fnts: 0,
	fngr: "F",
	fnrm: "Buck Up",

	scs: 0,
	ses: 0,
	sts: 0,
	sgr: "F",
	srm: "Buck Up",

	sscs: 0,
	sses: 0,
	ssts: 0,
	ssgr: "F",
	ssrm: "Buck Up",

	rmcs: 0,
	rmes: 0,
	rmts: 0,
	rmgr: "F",
	rmrm: "Buck Up",

	ictcs: 0,
	ictes: 0,
	ictts: 0,
	ictgr: "F",
	ictrm: "Buck Up",

	bdcs: 0,
	bdes: 0,
	bdts: 0,
	bdgr: "F",
	bdrm: "Buck Up",

	conduct: "",
	interest: "",
	attitude: "",
	remarks: "",
	ht_remarks: "",

	tapped: false,

	overrall_aggregate: 0,
	date_processed: new Date().toLocaleDateString(),
	year: new Date().getFullYear(),
	confirm_update: false,
	confirm_pss: false,
	message: "",
};
export default class JhsReport extends React.Component {
	constructor() {
		super();
		this.state = {
			mcs: 0,
			mes: 0,
			mts: 0,
			mgr: "F",
			mrm: "Buck Up",

			ecs: 0,
			ees: 0,
			ets: 0,
			egr: "F",
			erm: "Buck Up",

			fcs: 0,
			fes: 0,
			fts: 0,
			fgr: "F",
			frm: "Buck Up",

			fncs: 0,
			fnes: 0,
			fnts: 0,
			fngr: "F",
			fnrm: "Buck Up",

			scs: 0,
			ses: 0,
			sts: 0,
			sgr: "F",
			srm: "Buck Up",

			sscs: 0,
			sses: 0,
			ssts: 0,
			ssgr: "F",
			ssrm: "Buck Up",

			rmcs: 0,
			rmes: 0,
			rmts: 0,
			rmgr: "F",
			rmrm: "Buck Up",

			ictcs: 0,
			ictes: 0,
			ictts: 0,
			ictgr: "F",
			ictrm: "Buck Up",

			bdcs: 0,
			bdes: 0,
			bdts: 0,
			bdgr: "F",
			bdrm: "Buck Up",

			conduct: "",
			interest: "",
			attitude: "",
			remarks: "",
			ht_remarks: "",

			tapped: false,

			overrall_aggregate: 0,
			date_processed: new Date().toLocaleDateString(),
			year: new Date().getFullYear(),
			is_success: false,
			open_portal: false,
			current_doc: "",

			confirm_update: false,
			confirm_pss: false,
			message: "",
		};
	}

	getCourseScore = (name) => (e) => {
		if (parseFloat(e.target.value) > 100) {
			this.setState({ ...this.state, [name]: 0 });
		} else {
			this.setState({ ...this.state, [name]: parseFloat(e.target.value) }, () =>
				this.getCourseTotal()
			);
		}
	};

	getCourseTotal = () => {
		this.setState(
			{
				...this.state,
				mts: parseFloat(
					0.5 * (parseFloat(this.state.mcs) + parseFloat(this.state.mes))
				),
				ets: parseFloat(
					0.5 * (parseFloat(this.state.ecs) + parseFloat(this.state.ees))
				),
				sts: parseFloat(
					0.5 * (parseFloat(this.state.scs) + parseFloat(this.state.ses))
				),
				ssts: parseFloat(
					0.5 * (parseFloat(this.state.sscs) + parseFloat(this.state.sses))
				),
				bdts: parseFloat(
					0.5 * (parseFloat(this.state.bdcs) + parseFloat(this.state.bdes))
				),
				ictts: parseFloat(
					0.5 * (parseFloat(this.state.ictcs) + parseFloat(this.state.ictes))
				),
				rmts: parseFloat(
					0.5 * (parseFloat(this.state.rmcs) + parseFloat(this.state.rmes))
				),
				fts: parseFloat(
					0.5 * (parseFloat(this.state.fcs) + parseFloat(this.state.fes))
				),

				fnts: parseFloat(
					0.5 * (parseFloat(this.state.fncs) + parseFloat(this.state.fnes))
				),
			},
			() => {
				this.setState(
					{
						...this.state,
						mgr: this.getCourseGrade(this.state.mts),
						egr: this.getCourseGrade(this.state.ets),
						sgr: this.getCourseGrade(this.state.sts),
						ssgr: this.getCourseGrade(this.state.ssts),
						bdgr: this.getCourseGrade(this.state.bdts),
						ictgr: this.getCourseGrade(this.state.ictts),
						rmgr: this.getCourseGrade(this.state.rmts),
						fgr: this.getCourseGrade(this.state.fts),

						fngr: this.getCourseGrade(this.state.fnts),
					},
					() => {
						this.setState({
							...this.stage,
							mrm: this.getCourseRemarks(this.state.mgr),
							erm: this.getCourseRemarks(this.state.egr),
							srm: this.getCourseRemarks(this.state.sgr),
							ssrm: this.getCourseRemarks(this.state.ssgr),
							bdrm: this.getCourseRemarks(this.state.bdgr),
							rmrm: this.getCourseRemarks(this.state.rmgr),
							ictrm: this.getCourseRemarks(this.state.ictgr),
							frm: this.getCourseRemarks(this.state.fgr),
							fnrm: this.getCourseRemarks(this.state.fngr),
							overrall_aggregate:
								this.state.mts +
								this.state.sts +
								this.state.ssts +
								this.state.ets +
								this.state.fts +
								this.state.rmts +
								this.state.ictts +
								this.state.bdts +
								this.state.rmts +
								this.state.fnts,
						});
					}
				);
			}
		);
	};

	getCourseGrade = (tscore) => {
		if (tscore >= 80) {
			return "A";
		} else if (tscore >= 70 && tscore <= 79.99) {
			return "B";
		} else if (tscore >= 60 && tscore <= 69.99) {
			return "C";
		} else if (tscore >= 50 && tscore <= 59.99) {
			return "D";
		} else if (tscore >= 40 && tscore <= 49.99) {
			return "E";
		} else {
			return "F";
		}
	};

	getCourseRemarks = (grade) => {
		if (grade === "A") {
			return "Excellent";
		} else if (grade === "B") {
			return "Very Good";
		} else if (grade === "C") {
			return "Good";
		} else if (grade === "D") {
			return "Credit";
		} else if (grade === "E") {
			return "Pass";
		} else {
			return "Fail";
		}
	};

	updateState = (_id) => {
		if (_id === "not_available") {
			this.setState({ ...INITIAL_STATE });
		} else {
			db.collection("examination_reports")
				.doc(_id)
				.get()
				.then((doc) => {
					if (doc.exists) {
						this.setState({
							...doc.data(),
							confirm_update: false,
							confirm_pss: false,
							tapped: false,
						});
					}
				});
		}
	};

	updateReport = (_id) => {
		const csA = this.getCurrentStudentAttendance(
			this.props.current_student.student_id,
			this.props.term
		);

		const new_data = {
			...this.state,
			...this.props.current_student,
			term: this.props.term,
			academic_year: this.props.sett.academic_year,
			attendance: csA,
			total_days: parseInt(this.props.sett.number_of_weeks * 5),
			next_term: this.props.sett.next_term_begins,
		};
		db.collection("examination_reports")
			.doc(_id)
			.update(new_data)
			.then((resp) => {
				this.setState({ is_success: true, message: "Report Update" }, () =>
					this.setState({ ...INITIAL_STATE })
				);
			})
			.catch((err) => console.log(err));
	};

	getCurrentStudentAttendance = (student_id, term) => {
		let attendStatus = _.filter(this.props.attendance, (atd) => {
			return (
				atd.term === term &&
				atd.year === new Date().getFullYear() &&
				atd.class === this.props.current_student.class_admitted
			);
		});

		let totalPresent = 0;

		if (attendStatus === undefined || attendStatus === []) {
			return 0;
		} else {
			for (var status in attendStatus) {
				for (var p in attendStatus[status].data) {
					if (attendStatus[status].data[p].student_id === student_id) {
						totalPresent += 1;
					}
				}
			}
			return totalPresent;
		}
	};

	submitReport = (event) => {
		const csA = this.getCurrentStudentAttendance(
			this.props.current_student.student_id,
			this.props.term
		);

		const new_data = {
			...this.state,
			...this.props.current_student,
			term: this.props.term,
			academic_year: this.props.sett.academic_year,
			attendance: csA,
			total_days: parseInt(this.props.sett.number_of_weeks * 5),
			n_courses: 9,
			next_term: this.props.sett.next_semester_begins,
		};
		//checking availability of data of individual.
		db.collection("examination_reports")
			.add(new_data)
			.then((resp) =>
				this.setState({
					is_success: true,
					message: "Report Processing",
					...INITIAL_STATE,
				})
			)
			.catch((err) => {
				alert(err.message);
			});
	};

	randomRange = (min, max) => {
		return Math.floor(
			Math.random() * (parseInt(max) - parseInt(min) + 1) + parseInt(min)
		);
	};

	generateRemarks = () => {
		this.setState({
			conduct: conducts[this.randomRange(0, conducts.length - 1)].content,
			attitude: attitudes[this.randomRange(0, attitudes.length - 1)].content,
			interest: interests[this.randomRange(0, interests.length - 1)].content,
			remarks: remarks[this.randomRange(0, remarks.length - 1)].content,
		});
	};

	render() {
		const _inputs = [
			{
				course: "Mathematics",
				_foc: (
					<Input
						required
						value={this.state.mcs}
						type="number"
						onChange={this.getCourseScore("mcs")}
					/>
				),
				_foe: (
					<Input
						required
						value={this.state.mes}
						type="number"
						onChange={this.getCourseScore("mes")}
					/>
				),
				_hoa: <Input value={this.state.mts} readOnly />,
				_grd: <Input value={this.state.mgr} readOnly />,
				_rm: <Input value={this.state.mrm} readOnly />,
			},
			{
				course: "English",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.ecs}
						onChange={this.getCourseScore("ecs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.ees}
						onChange={this.getCourseScore("ees")}
					/>
				),
				_hoa: <Input value={this.state.ets} readOnly />,
				_grd: <Input value={this.state.egr} readOnly />,
				_rm: <Input value={this.state.erm} readOnly />,
			},
			{
				course: "Social Studies",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.sscs}
						onChange={this.getCourseScore("sscs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.sses}
						onChange={this.getCourseScore("sses")}
					/>
				),
				_hoa: <Input value={this.state.ssts} readOnly />,
				_grd: <Input value={this.state.ssgr} readOnly />,
				_rm: <Input value={this.state.ssrm} readOnly />,
			},
			{
				course: "Integrated Science",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.scs}
						onChange={this.getCourseScore("scs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.ses}
						onChange={this.getCourseScore("ses")}
					/>
				),
				_hoa: <Input value={this.state.sts} readOnly />,
				_grd: <Input value={this.state.sgr} readOnly />,
				_rm: <Input value={this.state.srm} readOnly />,
			},
			{
				course: "Rel. Moral Edu",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.rmcs}
						onChange={this.getCourseScore("rmcs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.rmes}
						onChange={this.getCourseScore("rmes")}
					/>
				),
				_hoa: <Input value={this.state.rmts} readOnly />,
				_grd: <Input value={this.state.rmgr} readOnly />,
				_rm: <Input value={this.state.rmrm} readOnly />,
			},
			{
				course: "Info. & Com. T",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.ictcs}
						onChange={this.getCourseScore("ictcs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.ictes}
						onChange={this.getCourseScore("ictes")}
					/>
				),
				_hoa: <Input value={this.state.ictts} readOnly />,
				_grd: <Input value={this.state.ictgr} readOnly />,
				_rm: <Input value={this.state.ictrm} readOnly />,
			},
			{
				course: "Fantse",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.fncs}
						onChange={this.getCourseScore("fncs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.fnes}
						onChange={this.getCourseScore("fnes")}
					/>
				),
				_hoa: <Input value={this.state.fnts} readOnly />,
				_grd: <Input value={this.state.fngr} readOnly />,
				_rm: <Input value={this.state.fnrm} readOnly />,
			},
			{
				course: "French",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.fcs}
						onChange={this.getCourseScore("fcs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.fes}
						onChange={this.getCourseScore("fes")}
					/>
				),
				_hoa: <Input value={this.state.fts} readOnly />,
				_grd: <Input value={this.state.fgr} readOnly />,
				_rm: <Input value={this.state.frm} readOnly />,
			},
			{
				course: "Basic Design T",
				_foc: (
					<Input
						required
						type="number"
						value={this.state.bdcs}
						onChange={this.getCourseScore("bdcs")}
					/>
				),
				_foe: (
					<Input
						required
						type="number"
						value={this.state.bdes}
						onChange={this.getCourseScore("bdes")}
					/>
				),
				_hoa: <Input value={this.state.bdts} readOnly />,
				_grd: <Input value={this.state.bdgr} readOnly />,
				_rm: <Input value={this.state.bdrm} readOnly />,
			},
		];

		return (
			<>
				<Confirm
					size="mini"
					header="Report Update Confirm"
					content="This Action will Update Existing Report"
					open={this.state.confirm_update}
					onCancel={() => this.setState({ confirm_update: false })}
					onConfirm={() =>
						this.setState(
							{ confirm_update: false },
							this.updateReport(this.props.report_id)
						)
					}
				/>

				<Confirm
					size="mini"
					header="Report Processing Confirm"
					content="This Action will add new Report"
					open={this.state.confirm_pss}
					onCancel={() => this.setState({ confirm_pss: false })}
					onConfirm={(e) =>
						this.setState({ confirm_pss: false }, this.submitReport)
					}
				/>
				<Feedback
					message={this.state.message}
					close_feedback={(name) => {
						this.setState({ is_success: false });
					}}
					open_feedback={this.state.is_success}
					message_type={"success"}
				/>
				<Form onSubmit={this.submitReport} inverted={this.props.dark_mode}>
					<Table celled unstackable inverted={this.props.dark_mode}>
						<Table.Header>
							<Table.Row>
								<Table.HeaderCell colSpan="3">
									Current Fill:{" "}
									{this.props._new
										? this.props.current_student.last_name +
										  " " +
										  this.props.current_student.other_names
										: "No Student Selected!"}
								</Table.HeaderCell>
								<Table.HeaderCell>
									Processed:{" "}
									{this.props.is_processed === "processed" ? (
										<Icon color="green" name="checkmark" />
									) : (
										"No"
									)}
								</Table.HeaderCell>
								<Table.HeaderCell>
									Aggregate: {this.state.overrall_aggregate}
								</Table.HeaderCell>
								<Table.HeaderCell>
									Junior High: {this.props.term}
								</Table.HeaderCell>
								<Table.HeaderCell>
									{this.props.is_processed === "processed" ? (
										<Button
											title="Update Report"
											icon="sync"
											size="mini"
											circular
											color="teal"
											onClick={() => this.updateState(this.props.report_id)}
										/>
									) : (
										<Button
											title="Reset Report"
											circular
											size="mini"
											icon="sync"
											color="red"
											onClick={() => this.setState({ ...INITIAL_STATE })}
										/>
									)}
								</Table.HeaderCell>
							</Table.Row>
							<Table.Row>
								<Table.HeaderCell>Subjects</Table.HeaderCell>
								<Table.HeaderCell textAlign={"center"}>
									{" "}
									Class Score
								</Table.HeaderCell>
								<Table.HeaderCell textAlign={"center"}>
									{" "}
									Exams score
								</Table.HeaderCell>
								<Table.HeaderCell textAlign={"center"}>
									100% Total Score
								</Table.HeaderCell>
								<Table.HeaderCell>Grade</Table.HeaderCell>
								<Table.HeaderCell colSpan="2">Remarks</Table.HeaderCell>
							</Table.Row>
						</Table.Header>
						<Table.Body>
							{_inputs.map((crs) => (
								<Table.Row key={crs.course}>
									<Table.Cell>{crs.course}</Table.Cell>
									<Table.Cell>{crs._foc}</Table.Cell>
									<Table.Cell>{crs._foe}</Table.Cell>
									<Table.Cell>{crs._hoa}</Table.Cell>
									<Table.Cell>{crs._grd}</Table.Cell>
									<Table.Cell colSpan="2">{crs._rm}</Table.Cell>
								</Table.Row>
							))}
						</Table.Body>
						<Table.Footer>
							<Table.Row>
								<Table.HeaderCell colSpan="3">
									<Input
										fluid
										label="Interest"
										value={this.state.interest}
										onChange={(e) =>
											this.setState({ interest: e.target.value })
										}
									/>
								</Table.HeaderCell>
								<Table.HeaderCell colSpan="3">
									<Input
										fluid
										label="Conduct"
										value={this.state.conduct}
										onChange={(e) => this.setState({ conduct: e.target.value })}
									/>
								</Table.HeaderCell>
								<Table.HeaderCell rowSpan="3">
									<Button
										onClick={() => this.generateRemarks()}
										circular
										icon="sync"
										color="orange"
										size="tiny"
									/>
								</Table.HeaderCell>
							</Table.Row>

							<Table.Row>
								<Table.HeaderCell colSpan="3">
									<Input
										fluid
										label="Attitude"
										value={this.state.attitude}
										onChange={(e) =>
											this.setState({ attitude: e.target.value })
										}
									/>
								</Table.HeaderCell>
								<Table.HeaderCell colSpan="3">
									<Input
										fluid
										label="Remarks"
										value={this.state.remarks}
										onChange={(e) => this.setState({ remarks: e.target.value })}
									/>
								</Table.HeaderCell>
							</Table.Row>

							<Table.Row>
								<Table.HeaderCell colSpan="6">
									<Input
										fluid
										label="Head Teacher's Remarks"
										value={this.state.ht_remarks}
										onChange={(e) =>
											this.setState({ ht_remarks: e.target.value })
										}
									/>
								</Table.HeaderCell>
							</Table.Row>
						</Table.Footer>
					</Table>
					{this.props.report_id === "not_available" ? (
						<Button
							onClick={() => this.setState({ confirm_pss: true, tapped: true })}
							loading={this.state.tapped}
							size="tiny"
							color="orange"
							disabled={this.props._new === false}
						>
							<Icon name="save" /> Process Report
						</Button>
					) : (
						<Button
							onClick={() =>
								this.setState({ confirm_update: true, tapped: true })
							}
							loading={this.state.tapped}
							size="tiny"
							color="green"
							disabled={this.props._new === false}
						>
							<Icon name="save" /> Update Report
						</Button>
					)}
				</Form>
			</>
		);
	}
}
