import React from 'react'
import { Form, Button, Input } from 'semantic-ui-react'
import { db } from '../services'
import Feedback from './feedback'
import _ from 'lodash'


export default function TableForm(props) {
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [mType, setType] = React.useState('success')
    const [lesson1, setLesson1] = React.useState('')
    const [lesson2, setLesson2] = React.useState('')
    const [lesson3, setLesson3] = React.useState('')
    const [lesson4, setLesson4] = React.useState('')
    const [lesson5, setLesson5] = React.useState('')
    const [lesson6, setLesson6] = React.useState('')
    const [lesson7, setLesson7] = React.useState('')
    const [lesson8, setLesson8] = React.useState('')

    const submitTable = event => {
        event.preventDefault()

        let current_c = _.find(props.tables, (td) => { return td.day === props.day})

        if(current_c === null || current_c === undefined){
            db.collection('time_tables').add({
                class_name:props.current_class,
                day: props.day,
                term:props.term,
                date_set: new Date().toLocaleDateString(),
                lesson_1:lesson1,
                lesson_2:lesson2,
                lesson_3:lesson3,
                lesson_4:lesson4,
                lesson_5:lesson5,
                lesson_6:lesson6,
                lesson_7:lesson7,
                lesson_8:lesson8
        }).then(resp => {
            setOpen(true); setMessage('Field Addition'); setType('success');
            setLesson1('');setLesson2('');setLesson3('');setLesson4('');setLesson5('');setLesson5('');setLesson6('');setLesson7('');
        }).catch(err => {
            setOpen(true); setMessage(err.message); setType('error'); 
        })
        }else if(current_c.day === props.day && current_c.term === props.term){
            
            db.collection('time_tables').doc(current_c._id).update({
                class_name:props.current_class,
                day: props.day,
                term:props.term,
                date_set: new Date().toLocaleDateString(),
                lesson_1:lesson1,
                lesson_2:lesson2,
                lesson_3:lesson3,
                lesson_4:lesson4,
                lesson_5:lesson5,
                lesson_6:lesson6,
                lesson_7:lesson7,
                lesson_8:lesson8
        }).then(resp =>{
            setOpen(true); setMessage('Table Update'); setType('success');
            setLesson1('');setLesson2('');setLesson3('');setLesson4('');setLesson5('');setLesson5('');setLesson6('');setLesson7('');
        }).catch(err => {
            setOpen(true); setMessage(err.message); setType('error'); 
        })

        }else{
            db.collection('time_tables').add({
                class_name:props.current_class,
                day: props.day,
                term:props.term,
                date_set: new Date().toLocaleDateString(),
                lesson_1:lesson1,
                lesson_2:lesson2,
                lesson_3:lesson3,
                lesson_4:lesson4,
                lesson_5:lesson5,
                lesson_6:lesson6,
                lesson_7:lesson7,
                lesson_8:lesson8
        }).then(resp => {
            setOpen(true); setMessage('Field Addition'); setType('success');
            setLesson1('');setLesson2('');setLesson3('');setLesson4('');setLesson5('');setLesson5('');setLesson6('');setLesson7('');
        }).catch(err => {
            setOpen(true); setMessage(err.message); setType('error'); 
        })
        }

    }
    return (
        <Form onSubmit={submitTable} inverted>
            <Feedback open_feedback={open} message_type={mType} message={message} close_feedback={(e) => setOpen(false)} />
            <Form.Group widths="16">
                <Form.Field value={lesson1}  onChange={(e) => setLesson1(e.target.value)} control={Input} label="Lesson 1" />
                <Form.Field value={lesson2}  onChange={(e) => setLesson2(e.target.value)} control={Input} label="Lesson 2" /> 
            </Form.Group> 

            <Form.Group widths="16">
                <Form.Field value={lesson3}  onChange={(e) => setLesson3(e.target.value)} control={Input} label="Lesson 3" />
                <Form.Field value={lesson4}  onChange={(e) => setLesson4(e.target.value)} control={Input} label="Lesson 4" /> 
            </Form.Group>

            <Form.Group widths="16">
                <Form.Field value={lesson5}  onChange={(e) => setLesson5(e.target.value)} control={Input} label="Lesson 5" />
                <Form.Field value={lesson6}  onChange={(e) => setLesson6(e.target.value)} control={Input} label="Lesson 6" /> 
            </Form.Group>

            <Form.Group widths="16">
                <Form.Field value={lesson7}  onChange={(e) => setLesson7(e.target.value)} control={Input} label="Lesson 7" />
                <Form.Field value={lesson8}  onChange={(e) => setLesson8(e.target.value)} control={Input} label="Lesson 8" /> 
            </Form.Group>

        
            <Button icon='send' type='submit' circular color="orange" />
        </Form>
    )
}