export const getCourseRemarks = (grade) => {
    if (grade === "A1") {
      return "Advance";
    }
    if (grade === "B2") {
      return "Approaching Advance";
    }
    if (grade === "B3") {
      return "Proficient ";
    }
  
    if (grade === "C4") {
      return "Approaching Proficient";
    }
    if (grade === "C5") {
      return "Developing";
    }
    if (grade === "C6") {
      return "Beginning";
    }
    if (grade === "D7") {
      return "Satisfactory";
    }
    if (grade === "E8") {
      return "Below";
    } else {
      return "Fail";
    }
  };
  
  export const getCourseGrade = (tscore) => {
    if (tscore >= 85) {
      return "A1";
    } else if (tscore >= 80 && tscore <= 84.99) {
      return "B2";
    } else if (tscore >= 75 && tscore <= 79.99) {
      return "B3";
    } else if (tscore >= 70 && tscore <= 74.99) {
      return "C4";
    } else if (tscore >= 65 && tscore <= 69.99) {
      return "C5";
    } else if (tscore >= 60 && tscore <= 64.99) {
      return "C6";
    } else if (tscore >= 50 && tscore <= 59.99) {
      return "D7";
    } else if (tscore >= 40 && tscore <= 49.99) {
      return "E8";
    } else {
      return "F9";
    }
  };
  