import React from 'react'
import { Segment, Icon, TransitionablePortal, Header, Comment } from 'semantic-ui-react'
import placeImage from '../images/placeholder-image.png'
import {auth, db} from '../services'

export default function Notification({ open, messages }) {
    return (
        <TransitionablePortal open={open} transition={{ animation: 'fly down', duration: 500 }}>
            <Segment style={{ width: '500px', position: 'absolute', right: 20, top: 50 }}>
                <Header content="Notifications" as='h3' dividing />
                <Comment.Group>
                    {messages.map(msg => (
                        <Comment key={msg._id}>
                            <Comment.Avatar src={msg.profile_image === null || msg.profile_image === undefined ? placeImage : msg.profile_image} />
                            <Comment.Content>
                                <Comment.Author as='a'>{msg.from}</Comment.Author>
                                <Comment.Metadata>
                                    <div>{msg.date + " at " + msg.time}</div>
                                </Comment.Metadata>
                                <Comment.Text>{msg.content}</Comment.Text>
                                <Comment.Actions>
                                    {msg.author_id === auth().currentUser.email ? 
                                    <Comment.Action onClick={(e) => {
                                        db.collection('notifications').doc(msg._id).delete().then(resp => {}).catch(err => {})
                                    }}>
                                        <Icon name='trash alternate outline' circular color='red' />
                                    </Comment.Action>
                                     : null}
                                </Comment.Actions>
                            </Comment.Content>
                        </Comment>
                    ))}

                </Comment.Group>
            </Segment>
        </TransitionablePortal>
    )
}