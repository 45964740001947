const uuid = require('uuid')


export const _courses = [
   
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English',
        value: 'English Language'
    },

    {
        text: 'Science',
        value: 'Integrated Science'
    },

    {
        text: 'Social',
        value: 'Social Studies'
    },
    {
        text: 'I C T',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'R M E',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'B D T',
        value: 'Basic Design & Tech.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'O W O P',
        value: 'Our World Our People'
    },
    {
        text: 'Fantse',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    },
    {
        text:"C Arts",
        value:"Creative Arts"
    },
    {
        text:"C. Tech",
        value:"Career Technology"
    },
    {
        text:"P & Health",
        value:"Physical and Health Education"
    }
]



export const _days = [
    {
        text: 'Sunday',
        value: "Sunday",
        key: uuid.v4()
    },
    {
        text: 'Monday',
        value: "Monday",
        key: uuid.v4()
    },
    {
        text: 'Tuesday',
        value: "Tuesday",
        key: uuid.v4()
    },
    {
        text: 'Wednesday',
        value: "Wednesday",
        key: uuid.v4()
    },
    {
        text: 'Thursday',
        value: "Thursday",
        key: uuid.v4()
    },
    {
        text: 'Friday',
        value: "Friday",
        key: uuid.v4()
    },
    {
        text: 'Saturday',
        value: "Saturday",
        key: uuid.v4()
    }

]

export const weeks =[
    {
        text: 'Week 1',
        value:'Week 1'
    },
    {
        text: 'Week 2',
        value:'Week 2'
    },
    {
        text: 'Week 3',
        value:'Week 3'
    },
    {
        text: 'Week 4',
        value:'Week 4'
    },
    {
        text: 'Week 5',
        value:'Week 5'
    },
    {
        text: 'Week 6',
        value:'Week 6'
    },
    {
        text: 'Week 7',
        value:'Week 7'
    },
    {
        text: 'Week 8',
        value:'Week 8'
    },
    {
        text: 'Week 9',
        value:'Week 9'
    },
    {
        text: 'Week 10',
        value:'Week 10'
    },
    {
        text: 'Week 11',
        value:'Week 11'
    },
    {
        text: 'Week 12',
        value:'Week 12'
    },
    {
        text: 'Week 13',
        value:'Week 13'
    },
    {
        text: 'Week 14',
        value:'Week 14'
    },
    {
        text: 'Week 15',
        value:'Week 15'
    },
    {
        text: 'Week 16',
        value:'Week 16'
    }
]

export const jhs_courses = [
   
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English',
        value: 'English Language'
    },

    {
        text: 'Science',
        value: 'Integrated Science'
    },

    {
        text: 'Social',
        value: 'Social Studies'
    },
    {
        text: 'I C T',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'R M E',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'B D T',
        value: 'Basic Design & Tech.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'Fantse',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    },
    {
        text:"C Arts",
        value:"Creative Arts"
    },
    {
        text:"C. Tech",
        value:"Career Technology"
    },
    {
        text:"P & Health",
        value:"Physical and Health Education"
    }
]

export const ups_courses = [
   
    {
        text: 'Mathematics',
        value: 'Mathematics'
    },
    {
        text: 'English',
        value: 'English Language'
    },

    {
        text: 'Science',
        value: 'Integrated Science'
    },

    {
        text: 'I C T',
        value: 'Info & Comm. Tech.'
    },
    {
        text: 'R M E',
        value: 'Religious & Moral Edu.'
    },
    {
        text: 'B D T',
        value: 'Basic Design & Tech.'
    },
    {
        text: 'History',
        value: 'History'
    },
    {
        text: 'O W O P',
        value: 'Our World Our People'
    },
    {
        text: 'Fantse',
        value: 'Fantse'
    },
    {
        text: 'French',
        value: 'French'
    },
    {
        text:"C Arts",
        value:"Creative Arts"
    }
]

export const prs_courses = [
    {
        text:"Numeracy",
        value:"Numeracy"
    },
    {
        text:"Our World Our People",
        value:"OWOP"
    },
    {
        text:"Language and Literacy",
        value:"Language and Lit"
    },
    {
        text:"Writing",
        value:"Writing"
    },
    {
        text:"Phonics",
        value:"Phonics"
    },
    {
        text:"Creative Arts",
        value:"Creative Arts"
    }
]


export const all_classes = [
    {
        value: 'Nursery 1',
        text: 'Nursery 1',
        index:0

    },
    {
        value: 'Nursery 2',
        text: 'Nursery 2',
        index:1
  
    },
    {
        value: 'Kindergarten 1',
        text: 'Kindergarten 1',
        index:2
    
    },
    {
        value: 'Kindergarten 2',
        text: 'Kindergarten 2',
        index:3
    
    },
    {
        value: 'Basic 1',
        text: 'Basic 1',
        index:4
    
    },
    {
        value: 'Basic 2',
        text: 'Basic 2',
        index:5

    },
    {
        value: 'Basic 3',
        text: 'Basic 3',
        index:6
 
    },
    {
        value: 'Basic 4',
        text: 'Basic 4',
        index:7
    
    },

    {
        value: 'Basic 5',
        text: 'Basic 5',
        index:8

    },

    {
        value: 'Basic 6',
        text: 'Basic 6',
        index:9

    },
    {
        value: 'Basic 7',
        text: 'Basic 7',
        index:10

    },
    {
        value: 'Basic 8',
        text: 'Basic 8',
        index:11

    },
    {
        value: 'Basic 9',
        text: 'Basic 9',
        index:12

    },
    {
        value: 'Basic 10',
        text: 'Basic 10',
        index:13

    },
    {
        value: 'Junior High 2',
        text: 'Junior High 2',
        index:14
   
    },

    {
        value: 'Junior High 3',
        text: 'Junior High 3',
        index:15
      
    }
]


