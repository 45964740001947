import React from "react";
import Feedback from "../_calls/feedback";
import _ from "lodash";
import { db } from "../services";
import { all_classes } from "../_calls/data";
import { getCourseRemarks, getCourseGrade } from "../functions";
import { remarks, attitudes, interests, conducts } from "../_calls/remarks";
import {
  Table,
  Form,
  Button,
  Icon,
  Input,
  Confirm,Select,
  Segment,
} from "semantic-ui-react";

const INITIAL_STATE = {
  mcs: 0,
  mes: 0,
  mts: 0,
  mgr: "F",
  mrm: "Buck Up",

  ecs: 0,
  ees: 0,
  ets: 0,
  egr: "F",
  erm: "Buck Up",

  scs: 0,
  ses: 0,
  sts: 0,
  sgr: "F",
  srm: "Buck Up",

  sscs: 0,
  sses: 0,
  ssts: 0,
  ssgr: "F",
  ssrm: "Buck Up",

  ictcs: 0,
  ictes: 0,
  ictts: 0,
  ictgr: "F",
  ictrm: "Buck Up",

  fcs: 0,
  fes: 0,
  fts: 0,
  fgr: "F",
  frm: "Buck Up",

  fncs: 0,
  fnes: 0,
  fnts: 0,
  fngr: "F",
  fnrm: "Buck Up",

  rmcs: 0,
  rmes: 0,
  rmts: 0,
  rmgr: "F",
  rmrm: "Buck Up",

  ctcs: 0,
  ctes: 0,
  ctts: 0,
  ctgr: "F",
  ctrm: "Buck Up",

  cracs: 0,
  craes: 0, 
  crats: 0,
  cragr: "F",
  crarm: "Buck Up",

  conduct: "",
  interest: "",
  attitude: "",
  remarks: "",
  ht_remarks: "",

  phcs: 0,
  phes: 0,
  phts: 0,
  phgr: "F",
  phrm: "Buck Up",
  year: new Date().getFullYear(),
  tapped:false
};
export default class CoursedSheet extends React.Component {
  constructor() {
    super();
    this.state = {
      mcs: 0,
      mes: 0,
      mts: 0,
      mgr: "F",
      mrm: "Buck Up",

      ecs: 0,
      ees: 0,
      ets: 0,
      egr: "F",
      erm: "Buck Up",

      scs: 0,
      ses: 0,
      sts: 0,
      sgr: "F",
      srm: "Buck Up",

      sscs: 0,
      sses: 0,
      ssts: 0,
      ssgr: "F",
      ssrm: "Buck Up",

      ictcs: 0,
      ictes: 0,
      ictts: 0,
      ictgr: "F",
      ictrm: "Buck Up",

      fcs: 0,
      fes: 0,
      fts: 0,
      fgr: "F",
      frm: "Buck Up",

      fncs: 0,
      fnes: 0,
      fnts: 0,
      fngr: "F",
      fnrm: "Buck Up",

      rmcs: 0,
      rmes: 0,
      rmts: 0,
      rmgr: "F",
      rmrm: "Buck Up",

      ctcs: 0,
      ctes: 0,
      ctts: 0,
      ctgr: "F",
      ctrm: "Buck Up",

      cracs: 0,
      craes: 0,
      crats: 0,
      cragr: "F",
      crarm: "Buck Up",

      phcs: 0,
      phes: 0,
      phts: 0,
      phgr: "F",
      phrm: "Buck Up",

      conduct: "",
      interest: "",
      attitude: "",
      remarks: "",
      ht_remarks: "",

      overrall_aggregate: 0,
      date_processed: new Date().toLocaleDateString(),
      year: new Date().getFullYear(),

      is_success: false,
      tapped: false,

      confirm_update: false,
      confirm_pss: false,
      message: "",
    };
  }

  getCourseScore = (name) => (event) => {
    if (parseFloat(event.target.value) > 100) {
      this.setState({ ...this.state, [name]: 0 });
    } else {
      this.setState(
        { ...this.state, [name]: parseFloat(event.target.value) },
        () => this.getCourseTotal()
      );
    }
  };

  getCourseTotal = () => {
    this.setState(
      {
        ...this.state,
        mts: parseFloat(
          0.5 * (parseFloat(this.state.mcs) + parseFloat(this.state.mes))
        ),
        ets: parseFloat(
          0.5 * (parseFloat(this.state.ecs) + parseFloat(this.state.ees))
        ),
        sts: parseFloat(
          0.5 * (parseFloat(this.state.scs) + parseFloat(this.state.ses))
        ),
        ssts: parseFloat(
          0.5 * (parseFloat(this.state.sscs) + parseFloat(this.state.sses))
        ),
        ctts: parseFloat(
          0.5 * (parseFloat(this.state.ctcs) + parseFloat(this.state.ctes))
        ),
        ictts: parseFloat(
          0.5 * (parseFloat(this.state.ictcs) + parseFloat(this.state.ictes))
        ),
        rmts: parseFloat(
          0.5 * (parseFloat(this.state.rmcs) + parseFloat(this.state.rmes))
        ),
        fts: parseFloat(
          0.5 * (parseFloat(this.state.fcs) + parseFloat(this.state.fes))
        ),
        phts: parseFloat(
          0.5 * (parseFloat(this.state.phcs) + parseFloat(this.state.phes))
        ),
        fnts: parseFloat(
          0.5 * (parseFloat(this.state.fncs) + parseFloat(this.state.fnes))
        ),
        crats: parseFloat(
          0.5 * (parseFloat(this.state.cracs) + parseFloat(this.state.craes))
        ),
      },
      () => {
        this.setState(
          {
            ...this.state,
            mgr: getCourseGrade(this.state.mts),
            egr: getCourseGrade(this.state.ets),
            sgr: getCourseGrade(this.state.sts),
            ssgr: getCourseGrade(this.state.ssts),
            ctgr: getCourseGrade(this.state.ctts),
            ictgr: getCourseGrade(this.state.ictts),
            rmgr: getCourseGrade(this.state.rmts),
            fgr: getCourseGrade(this.state.fts),
            phgr: getCourseGrade(this.state.phts),
            cragr: getCourseGrade(this.state.crats),
            fngr: getCourseGrade(this.state.fnts),
          },
          () => {
            this.setState({
              ...this.stage,
              mrm: getCourseRemarks(this.state.mgr),
              erm: getCourseRemarks(this.state.egr),
              srm: getCourseRemarks(this.state.sgr),
              ssrm: getCourseRemarks(this.state.ssgr),
              ctrm: getCourseRemarks(this.state.ctgr),
              rmrm: getCourseRemarks(this.state.rmgr),
              ictrm: getCourseRemarks(this.state.ictgr),
              frm: getCourseRemarks(this.state.fgr),
              phrm: getCourseRemarks(this.state.phgr),
              fnrm: getCourseRemarks(this.state.fngr),
              crarm: getCourseRemarks(this.state.cragr),
              overrall_aggregate:
                this.state.phts +
                this.state.mts +
                this.state.sts +
                this.state.ssts +
                this.state.ets +
                this.state.fts +
                this.state.rmts +
                this.state.ictts +
                this.state.ctts +
                this.state.rmts +
                this.state.fnts +
                this.state.crats,
            });
          }
        );
      }
    );
  };



  updateState = (_id) => {
    if (_id === "not_available") {
      this.setState({ ...INITIAL_STATE });
    } else {
      db.collection("examination_reports")
        .doc(_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.setState({ ...doc.data() });
          }
        });
    }
  };

  updateReport = (_id) => {
    const csA = this.getCurrentStudentAttendance(
      this.props.current_student.student_id,
      this.props.term
    );

    const new_data = {
      ...this.state,
      ...this.props.current_student,
      term: this.props.term,
      academic_year: this.props.sett.academic_year,
      attendance: csA,
      total_days: parseInt(this.props.sett.number_of_weeks * 5),
      next_term: this.props.sett.next_term_begins,
      num_on_roll:this.props.roll
    };
    db.collection("examination_reports")
      .doc(_id)
      .update(new_data)
      .then((resp) => {
        this.setState({ is_success: true, message: "Report Update" }, () =>
          this.setState({ ...INITIAL_STATE })
        );
      })
      .catch((err) => console.log(err));
  };

  submitReport = (event) => {
    const csA = this.getCurrentStudentAttendance(
      this.props.current_student.student_id,
      this.props.term
    );

    const new_data = {
      ...this.state,
      ...this.props.current_student,
      n_courses: 11,
      term: this.props.term,
      academic_year: this.props.sett.academic_year,
      attendance: csA,
      total_days: parseInt(this.props.sett.number_of_weeks * 5),
      next_term: this.props.sett.next_semester_begins,
      num_on_roll:this.props.roll
    };
    //checking availability of data of individual.
    db.collection("examination_reports")
      .add(new_data)
      .then(() =>
        this.setState({
          is_success: true,
          message: "Report Processing",
          ...INITIAL_STATE,
        })
      )
      .catch((err) => {
        console.log(err.message);
      });
  };

  getCurrentStudentAttendance = (student_id, term) => {
    let attendStatus = _.filter(this.props.attendance, (atd) => {
      return (
        atd.term === term &&
        atd.year === new Date().getFullYear() &&
        atd.class === this.props.current_student.class_admitted
      );
    });
    let totalPresent = 0;

    if (attendStatus.length < 1) {
      return 0;
    } else {
      for (var status in attendStatus) {
        for (var p in attendStatus[status].data) {
          if (attendStatus[status].data[p].student_id === student_id) {
            totalPresent += 1;
          }
        }
      }
      return totalPresent;
    }
  };

  randomRange = (min, max) => {
    return Math.floor(
      Math.random() * (parseInt(max) - parseInt(min) + 1) + parseInt(min)
    );
  };

  generateRemarks = () => {
    this.setState({
      conduct: conducts[this.randomRange(0, conducts.length - 1)].content,
      attitude: attitudes[this.randomRange(0, attitudes.length - 1)].content,
      interest: interests[this.randomRange(0, interests.length - 1)].content,
      remarks: remarks[this.randomRange(0, remarks.length - 1)].content,
    });
  };

  render() {
    const _inputs = [
      {
        cname: "Mathematics",
        _foc: (
          <Input
            type="number"
            value={this.state.mcs}
            onChange={this.getCourseScore("mcs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.mes}
            onChange={this.getCourseScore("mes")}
          />
        ),
        _hoa: <Input value={this.state.mts} readOnly />,
        _grd: <Input value={this.state.mgr} readOnly />,
        _rm: <Input value={this.state.mrm} readOnly />,
      },
      {
        cname: "English Lang.",
        _foc: (
          <Input
            type="number"
            value={this.state.ecs}
            onChange={this.getCourseScore("ecs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.ees}
            onChange={this.getCourseScore("ees")}
          />
        ),
        _hoa: <Input value={this.state.ets} readOnly />,
        _grd: <Input value={this.state.egr} readOnly />,
        _rm: <Input value={this.state.erm} readOnly />,
      },
      {
        cname: "Science",
        _foc: (
          <Input
            type="number"
            value={this.state.scs}
            onChange={this.getCourseScore("scs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.ses}
            onChange={this.getCourseScore("ses")}
          />
        ),
        _hoa: <Input value={this.state.sts} readOnly />,
        _grd: <Input value={this.state.sgr} readOnly />,
        _rm: <Input value={this.state.srm} readOnly />,
      },
      {
        cname: "Social Studies",
        _foc: (
          <Input
            type="number"
            value={this.state.sscs}
            onChange={this.getCourseScore("sscs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.sses}
            onChange={this.getCourseScore("sses")}
          />
        ),
        _hoa: <Input value={this.state.ssts} readOnly />,
        _grd: <Input value={this.state.ssgr} readOnly />,
        _rm: <Input value={this.state.ssrm} readOnly />,
      },
      {
        cname: "Gh. Language",
        _foc: (
          <Input
            type="number"
            value={this.state.fncs}
            onChange={this.getCourseScore("fncs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.fnes}
            onChange={this.getCourseScore("fnes")}
          />
        ),
        _hoa: <Input value={this.state.fnts} readOnly />,
        _grd: <Input value={this.state.fngr} readOnly />,
        _rm: <Input value={this.state.fnrm} readOnly />,
      },
      {
        cname: "French",
        _foc: (
          <Input
            type="number"
            value={this.state.fcs}
            onChange={this.getCourseScore("fcs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.fes}
            onChange={this.getCourseScore("fes")}
          />
        ),
        _hoa: <Input value={this.state.fts} readOnly />,
        _grd: <Input value={this.state.fgr} readOnly />,
        _rm: <Input value={this.state.frm} readOnly />,
      },
      {
        cname: "R M E",
        _foc: (
          <Input
            type="number"
            value={this.state.rmcs}
            onChange={this.getCourseScore("rmcs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.rmes}
            onChange={this.getCourseScore("rmes")}
          />
        ),
        _hoa: <Input value={this.state.rmts} readOnly />,
        _grd: <Input value={this.state.rmgr} readOnly />,
        _rm: <Input value={this.state.rmrm} readOnly />,
      },
      {
        cname: "Creative Arts",
        _foc: (
          <Input
            type="number"
            value={this.state.cracs}
            onChange={this.getCourseScore("cracs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.craes}
            onChange={this.getCourseScore("craes")}
          />
        ),
        _hoa: <Input value={this.state.crats} readOnly />,
        _grd: <Input value={this.state.cragr} readOnly />,
        _rm: <Input value={this.state.crarm} readOnly />,
      },
      {
        cname: "Computing",
        _foc: (
          <Input
            type="number"
            value={this.state.ictcs}
            onChange={this.getCourseScore("ictcs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.ictes}
            onChange={this.getCourseScore("ictes")}
          />
        ),
        _hoa: <Input value={this.state.ictts} readOnly />,
        _grd: <Input value={this.state.ictgr} readOnly />,
        _rm: <Input value={this.state.ictrm} readOnly />,
      },
      {
        cname: "Career Tech.",
        _foc: (
          <Input
            type="number"
            value={this.state.ctcs}
            onChange={this.getCourseScore("ctcs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.ctes}
            onChange={this.getCourseScore("ctes")}
          />
        ),
        _hoa: <Input value={this.state.ctts} readOnly />,
        _grd: <Input value={this.state.ctgr} readOnly />,
        _rm: <Input value={this.state.ctrm} readOnly />,
      },

      {
        cname: "Physical & Health",
        _foc: (
          <Input
            type="number"
            value={this.state.phcs}
            onChange={this.getCourseScore("phcs")}
          />
        ),
        _foe: (
          <Input
            type="number"
            value={this.state.phes}
            onChange={this.getCourseScore("phes")}
          />
        ),
        _hoa: <Input value={this.state.phts} readOnly />,
        _grd: <Input value={this.state.phgr} readOnly />,
        _rm: <Input value={this.state.phrm} readOnly />,
      },
    ];

    return (
      <Segment raised>
        <Confirm
          size="mini"
          header="Report Update Confirm"
          content="This Action will Update Existing Report"
          open={this.state.confirm_update}
          onCancel={() => this.setState({ confirm_update: false })}
          onConfirm={() =>
            this.setState(
              { confirm_update: false },
              this.updateReport(this.props.report_id)
            )
          }
        />

        <Confirm
          size="mini"
          header="Report Processing Confirm"
          content="This Action will add new Report"
          open={this.state.confirm_pss}
          onCancel={() => this.setState({ confirm_pss: false })}
          onConfirm={(e) =>
            this.setState({ confirm_pss: false }, this.submitReport)
          }
        />
        <Feedback
          message={this.state.message}
          close_feedback={(name) => {
            this.setState({ is_success: false });
          }}
          open_feedback={this.state.is_success}
          message_type={"success"}
        />

        <Form inverted={this.props.dark_sett}>
          <Table celled unstackable striped inverted={this.props.dark_sett}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  Current Fill:{" "}
                  {this.props._new
                    ? this.props.current_student.last_name +
                      " " +
                      this.props.current_student.other_names
                    : "No Student Selected!"}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Processed:{" "}
                  {this.props.is_processed === "processed" ? (
                    <Icon color="green" name="checkmark" />
                  ) : (
                    "No"
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Aggregate: {this.state.overrall_aggregate}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Junior High: {this.props.term}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.is_processed === "processed" ? (
                    <Button
                    title="Update Report"
                      icon="sync"
                      size="mini"
                      circular
                      color="teal"
                      onClick={() => this.updateState(this.props.report_id)}
                    />
                  ) : (
                    <Button
                    title="Reset Report"
                    circular 
                    size="mini" 
                      name="sync"
                      color="red"
                      onClick={() => this.setState({ ...INITIAL_STATE })}
                    />
                  )}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Subjects</Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  {" "}
                  Class Score
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  {" "}
                  Exams score
                </Table.HeaderCell>
                <Table.HeaderCell textAlign={"center"}>
                  100% Total Score
                </Table.HeaderCell>
                <Table.HeaderCell>Grade</Table.HeaderCell>
                <Table.HeaderCell colSpan="2">Remarks</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_inputs.map((crs) => (
                <Table.Row key={crs.cname}>
                  <Table.Cell>{crs.cname}</Table.Cell>
                  <Table.Cell>{crs._foc}</Table.Cell>
                  <Table.Cell>{crs._foe}</Table.Cell>
                  <Table.Cell>{crs._hoa}</Table.Cell>
                  <Table.Cell>{crs._grd}</Table.Cell>
                  <Table.Cell colSpan="2">{crs._rm}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Interest"
                    value={this.state.interest}
                    onChange={(e) =>
                      this.setState({ interest: e.target.value })
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Conduct"
                    value={this.state.conduct}
                    onChange={(e) => this.setState({ conduct: e.target.value })}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell rowSpan="3">
                  <Button
                    onClick={() => this.generateRemarks()}
                    circular
                    icon="sync"
                    color="orange"
                    size="tiny"
                  />
                </Table.HeaderCell>
              </Table.Row>

              <Table.Row>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Attitude"
                    value={this.state.attitude}
                    onChange={(e) =>
                      this.setState({ attitude: e.target.value })
                    }
                  />
                </Table.HeaderCell>
                <Table.HeaderCell colSpan="3">
                  <Input
                    fluid
                    label="Remarks"
                    value={this.state.remarks}
                    onChange={(e) => this.setState({ remarks: e.target.value })}
                  />
                </Table.HeaderCell>
              </Table.Row>

              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Input
                    fluid
                    label="Head Teacher's Remarks"
                    value={this.state.ht_remarks}
                    onChange={(e) =>
                      this.setState({ ht_remarks: e.target.value })
                    }
                  />
                </Table.HeaderCell>

                {this.props.term === "Third Term" && (
                  <Table.HeaderCell colSpan="2">
                    <Select
                      placeholder="Promoted To"
                      value={this.state.promoted_to}
                      options={all_classes}
                      onChange={(e, dt) =>
                        this.setState({ promoted_to: dt.value })
                      }
                    />
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Footer>
          </Table>
          {this.props.report_id === "not_available" ? (
            <Button
              onClick={() => this.setState({ confirm_pss: true, tapped: true })}
              loading={this.state.tapped}
              size="tiny"
              color="orange"
              disabled={this.props._new === false}
            >
              <Icon name="save" /> Process Report
            </Button>
          ) : (
            <Button
              onClick={() =>
                this.setState({ confirm_update: true, tapped: true })
              }
              loading={this.state.tapped}
              size="tiny"
              color="green"
              disabled={this.props._new === false}
            >
              <Icon name="save" /> Update Report
            </Button>
          )}
        </Form>
      </Segment>
    );
  }
}
