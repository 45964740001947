import React from "react";
import JhsReport from "./junior_high";
import LowerReport from "./lower_class";
import UpperReport from "./upper_primary";
import CoursedSheet from "./coursed-sheet";
export default class ExaminationTable extends React.Component {
	constructor() {
		super();
		this.state = {
			report_history: null,
			loading: true,
		};
	}

	getClassBase = (cls) => {
		return (
			cls === "Basic 7" ||
			cls === "Basic 8" ||
			cls === "Basic 9" ||
			cls === "Basic 10"
		);
	};

	render() {
		const sheet = this.props.current_sheet;
		const {
			profile_image,
			last_name,
			other_names,
			student_id,
			class_admitted,
			class_category,
		} = this.props.current_student;
		const student = {
			profile_image,
			last_name,
			other_names,
			student_id,
			class_admitted,
			class_category,
		};
		const sett = this.props.sett;

		const exams_props = {
			is_processed: this.props.is_processed,
			sett: sett,
			report_id: this.props.report_id,
			attendance: this.props.attendance,
			mode: this.props.mode,
			term: this.props.term,
			current_student: student,
			_new: this.props._new,
			current_sheet: sheet,
			roll:this.props.roll,
			_history: this.props.report_history,
		};

		let paper;
		switch (sheet) {
			case "upper_primary":
			case "lower_primary":
				paper = <UpperReport {...exams_props} />;
				break;
			case "junior_high":
				paper = this.getClassBase(student.class_admitted) ? (
					<CoursedSheet {...exams_props} />
				) : (
					<JhsReport {...exams_props} />
				);
				break;
			case "pre_school":
				paper = <LowerReport {...exams_props} />;
				break;
			default:
				paper = <UpperReport {...exams_props} />;
		}
		return <React.Fragment> {paper}</React.Fragment>;
	}
}
