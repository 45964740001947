import React from "react";
import {
	Segment,
	Table,
	Button,
	Tab,
	Input,
	Form,
	Grid,
	Dimmer,
	Confirm,
	Loader,
} from "semantic-ui-react";
import { db } from "../services";
import _ from "lodash";
import {
	_days,
	weeks,
	jhs_courses,
	ups_courses,
	prs_courses,
} from "../_calls/data";
import Feedback from "../_calls/feedback";

export default class Records extends React.Component {
	constructor() {
		super();
		this.state = {
			data: [],
			subject: "",
			preview_subject: ups_courses[0].value,
			day: "",
			week: "",
			opn: false,
			confirm_send: false,
			message: "",
			message_type: "",
			daily_records: [],
		};
	}

	componentDidMount() {
		db.collection("daily_records").onSnapshot((qss) => {
			let dT = [];
			qss.forEach((doc) => {
				dT.push({ ...doc.data(), _id: doc.id });
			});

			const records = _.filter(
				dT,
				(rc) => rc.current_class === this.props.profile.class_assigned
			);
			this.setState({ daily_records: records }, () => (dT = []));
		});
	}

	getTerm = () => {
		return this.props.settings.current_term;
	};

	processScore = (sid, value, etype) => {
		let data = this.state.data;

		let record = {};

		let status = _.find(data, (rd) => rd.student_id === sid);

		if (status === undefined) {
			record = {
				student_id: sid,
				ex_score: 0,
				pw_score: 0,
				as_score: 0,
				ct_score: 0,
			};
		} else {
			record = status;
		}

		switch (etype) {
			case "ex":
				record.ex_score = value;
				break;
			case "pw":
				record.pw_score = value;
				break;
			case "as":
				record.as_score = value;
				break;
			case "ct":
				record.ct_score = value;
				break;
			default:
				return 0;
		}

		let report_state = _.find(
			this.state.daily_records,
			(rd) =>
				rd.subject === this.state.subject &&
				rd.term === this.getTerm() &&
				new Date(rd.date_processed).getFullYear() ===
					new Date().getFullYear() &&
				rd.week === this.state.week &&
				rd.day === _days[new Date().getDay()].value
		);

		if (report_state === undefined) {
			//report doesn't exist. check if in state? remove
			//eslint-disable-next-line
			let new_arr = _.remove(data, (dt) => dt.student_id === record.student_id);
			data.push(record);
		} else {
			data = report_state.data;
			let status = _.find(data, (dt) => dt.student_id === sid);
			if (status === undefined) {
				// not data record for student
				data.push(record);
			} else {
				status = record;
				//eslint-disable-next-line
				let new_arr = _.remove(
					data,
					(dt) => dt.student_id === record.student_id
				);
				data.push(status);
			}
		}

		this.setState({ data });
	};

	updateReport = () => {
		const cclass = this.props.profile.class_assigned;
		let report = {
			current_class: cclass,
			date_processed: new Date().toDateString(),
			time_processed: new Date().toLocaleTimeString(),
			term: this.getTerm(),
			week: this.state.week,
			subject: this.state.subject,
			day: _days[new Date().getDay()].value,
			authorized: false,
			processed_by:
				this.props.profile.last_name + " " + this.props.profile.first_name,
			data: this.state.data,
		};

		const report_state = _.find(
			this.state.daily_records,
			(rd) =>
				new Date(rd.date_processed).getFullYear() ===
					new Date().getFullYear() &&
				rd.term === this.getTerm() &&
				rd.week === this.state.week &&
				rd.subject === this.state.subject &&
				rd.day === _days[new Date().getDay()].value
		);

		if (report_state === undefined) {
			db.collection("daily_records")
				.add(report)
				.then(() => {
					this.setState({
						opn: true,
						message_type: "success",
						message: "Reports Upload ",
					});
				})
				.catch((err) => {
					this.setState({
						opn: true,
						message_type: "error",
						message: err.message,
					});
				});
		} else {
			db.collection("daily_records")
				.doc(report_state._id)
				.update(report)
				.then(() => {
					this.setState({
						opn: true,
						message_type: "success",
						message: "Report Update",
					});
				})
				.catch((err) => {
					this.setState({
						opn: true,
						message_type: "error",
						message: err.message,
					});
				});
		}
	};

	getHistory = (student_id) => {
		let record_history = _.find(
			this.state.daily_records,
			(rd) =>
				rd.week === this.state.week &&
				rd.term === this.getTerm() &&
				new Date(rd.date_processed).getFullYear() ===
					new Date().getFullYear() &&
				rd.day === this.state.day &&
				rd.subject === this.state.preview_subject
		);

		if (record_history === undefined) {
			return undefined;
		} else {
			let idhistory = _.find(
				record_history.data,
				(sd) => sd.student_id === student_id
			);

			return idhistory === undefined
				? { ex_score: "N/A", ct_score: "N/A", pw_score: "N/A", as_score: "N/A" }
				: idhistory;
		}
	};

	filterCourses = () => {
		//eslint-disable-next-line
		let cls = this.props.profile.class_assigned;

		if (cls.charAt(0) === "N" || cls.charAt(0) === "K") {
			return prs_courses;
		} else if (
			[
				"Basic 1",
				"Basic 2",
				"Basic 3",
				"Basic 4",
				"Basic 5",
				"Basic 6",
			].includes(cls)
		) {
			return ups_courses;
		} else {
			//eslint-disable-next-line
			return jhs_courses;
		}
	};
	getSubjects = () => {
		const { students } = this.props;

		let subs = [];
		this.filterCourses().map((sc, idx) =>
			subs.push({
				menuItem: sc.text,
				render: () => (
					<Tab.Pane>
						<Table striped celled singleLine fixed>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell>Students</Table.HeaderCell>
									<Table.HeaderCell>Exercise</Table.HeaderCell>
									<Table.HeaderCell>Project Work</Table.HeaderCell>
									<Table.HeaderCell>Assignment</Table.HeaderCell>
									<Table.HeaderCell>Class Test</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{students.map((std) => (
									<Table.Row key={std.student_id}>
										<Table.Cell>
											{std.last_name + " " + std.other_names}
										</Table.Cell>
										<Table.Cell>
											<Input
												name="ex"
												max="100"
												type="number"
												onChange={(e) =>
													this.processScore(
														std.student_id,
														parseFloat(e.target.value),
														"ex"
													)
												}
											/>
										</Table.Cell>
										<Table.Cell>
											<Input
												name="pw"
												type="number"
												onChange={(e) =>
													this.processScore(
														std.student_id,
														parseFloat(e.target.value),
														"pw"
													)
												}
											/>
										</Table.Cell>
										<Table.Cell>
											<Input
												name="as"
												type="number"
												onChange={(e) =>
													this.processScore(
														std.student_id,
														parseFloat(e.target.value),
														"as"
													)
												}
											/>
										</Table.Cell>
										<Table.Cell>
											<Input
												name="ct"
												type="number"
												onChange={(e) =>
													this.processScore(
														std.student_id,
														parseFloat(e.target.value),
														"ct"
													)
												}
											/>
										</Table.Cell>
									</Table.Row>
								))}
							</Table.Body>
							<Table.Footer>
								<Table.Row>
									<Table.Cell colSpan="5">
										<Button
											disabled={
												!this.state.week ||
												this.state.data.length < 1 ||
												new Date().getDay() === 0 ||
												new Date().getDay() === 6
											}
											floated="right"
											onClick={() => this.setState({ confirm_send: true })}
											color="blue"
										>
											Update Today's Report
										</Button>
									</Table.Cell>
								</Table.Row>
							</Table.Footer>
						</Table>
					</Tab.Pane>
				),
			})
		);
		return subs;
	};
	render() {
		const { students } = this.props;

		const panes = [
			{
				menuItem: "Overview",
				render: () => (
					<Tab.Pane>
						<Grid padded stackable centered>
							<Grid.Column computer={4}>
								<Form>
									<Form.Select
										options={weeks}
										label="Select Week"
										onChange={(e, target) =>
											this.setState({ week: target.value })
										}
										value={this.state.week}
									/>
									<Form.Select
										options={_days}
										value={this.state.day}
										label="Select Day"
										onChange={(e, target) =>
											this.setState({ day: target.value })
										}
									/>
									<Form.Select
										options={this.filterCourses()}
										value={this.state.preview_subject}
										label="Select Subject"
										onChange={(e, target) =>
											this.setState({ preview_subject: target.value })
										}
									/>
								</Form>
							</Grid.Column>
							<Grid.Column computer={12}>
								{this.getHistory() === undefined ? (
									<Segment placeholder inverted>
										<Dimmer active>
											<Loader indeterminate size="big">
												No Data Loaded
											</Loader>
										</Dimmer>
									</Segment>
								) : (
									<Table striped celled singleLine fixed>
										<Table.Header>
											<Table.Row>
												<Table.HeaderCell>Students</Table.HeaderCell>
												<Table.HeaderCell>Exercise</Table.HeaderCell>
												<Table.HeaderCell>Project Work</Table.HeaderCell>
												<Table.HeaderCell>Assignment</Table.HeaderCell>
												<Table.HeaderCell>Class Test</Table.HeaderCell>
											</Table.Row>
										</Table.Header>
										<Table.Body>
											{students.map((std) => (
												<Table.Row key={std.student_id}>
													<Table.Cell>
														{std.last_name + " " + std.other_names}
													</Table.Cell>
													<Table.Cell>
														{this.getHistory(std.student_id).ex_score}
													</Table.Cell>
													<Table.Cell>
														{this.getHistory(std.student_id).pw_score}
													</Table.Cell>
													<Table.Cell>
														{this.getHistory(std.student_id).as_score}
													</Table.Cell>
													<Table.Cell>
														{this.getHistory(std.student_id).ct_score}
													</Table.Cell>
												</Table.Row>
											))}
										</Table.Body>
									</Table>
								)}
							</Grid.Column>
						</Grid>
					</Tab.Pane>
				),
			},
			...this.getSubjects(),
		];

		return (
			<Segment raised>
				<Confirm
					closeOnDimmerClick={false}
					size="mini"
					open={this.state.confirm_send}
					header="Confirm Report Update !"
					content="This action will update Students Report. Continue ? "
					confirmButton={
						<Button color="green" size="tiny">
							Yes Continue
						</Button>
					}
					cancelButton={
						<Button color="red" size="tiny">
							No Cancel
						</Button>
					}
					onCancel={() => this.setState({ confirm_send: false })}
					onConfirm={() =>
						this.setState({ confirm_send: false }, () => this.updateReport())
					}
				/>
				<Feedback
					open_feedback={this.state.opn}
					close_feedback={(n) => this.setState({ opn: false })}
					message={this.state.message}
					message_type={this.state.message_type}
				/>
				<Tab
					panes={panes}
					onTabChange={(e, data) =>
						this.setState({
							subject:
								data.activeIndex === 0
									? " "
									: this.filterCourses()[data.activeIndex - 1].value,
						})
					}
				/>
			</Segment>
		);
	}
}
