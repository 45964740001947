import React from "react";
import {
  Grid,
  Segment,
  Header,
  List,
  Divider,
  Item,
  Table,
  Button,
  Image,
  Icon,
  Confirm,
} from "semantic-ui-react";
import _ from "lodash";
import generate_report from "../_calls/report-generator";
import generate_pre_report from "../_calls/pre_school_report";
import { db } from "../services";

export default class Files extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      term:
        this.getTermType(this.props.profile.class_assigned) === true
          ? this.props.sett.current_semester
          : this.props.sett.current_term,
      current_class: this.props.profile.class_assigned,
      no_data: true,
      show_class: false,
      class_category: "lower_primary",
      current_report: null,
      current_student: null,
      new_loaded: false,
      confirm_delete: false,
      data_image: null,
      report_id: "not_available",
    };
  }

  getTermType = (cl) => {
    return (
      cl.charAt(0) === "J" ||
      cl === "Basic 7" ||
      cl === "Basic 8" ||
      cl === "Basic 9" ||
      cl === "Basic 10"
    );
  };

  getStudentReport = () => {
    var _new = _.find(this.props._history, (rpt) => {
      return (
        rpt.student_id === this.state.current_student.student_id &&
        rpt.class_admitted === this.state.current_student.class_admitted &&
        rpt.term === this.state.term
      );
    });

    if (_new === undefined) {
      this.setState({ new_loaded: false });
    } else if (
      new Date(_new.date_processed).getFullYear() === this.state.year
    ) {
      this.getImage(_new.profile_image, _new);
      this.setState({ current_report: _new, new_loaded: true });
    }
  };

  deleteBetaReport = (_id) => {
    db.collection("examination_reports")
      .doc(_id)
      .delete()
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  getBetaReport = () => {
    let unsorted = _.filter(this.props._history, (crp) => {
      return (
        crp.class_admitted === this.state.current_class &&
        crp.term === this.state.term &&
        new Date(crp.date_processed).getFullYear() === parseInt(this.state.year)
      );
    });
    return _.sortBy(unsorted, (std) => {
      return std.last_name;
    });
  };

  downloadBetaReport = (report) => {
    this.setState({ current_report: report }, () =>
      this.getCurrentClassReport("download")
    );
  };

  sortResults = (arr) => {
    return arr
      .sort((a, b) => {
        return a - b;
      })
      .reverse();
  };

  getImage = (url, exam) =>
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = () => {
          exam.profile_image = reader.result;
        };
        reader.onerror = (err) => {
          console.log(err);
        };
        reader.readAsDataURL(blob);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });

  getCurrentClassReport = (method) => {
    if (this.props._history !== null) {
      //get report for current_class for current_term
      var current_report = _.filter(this.props._history, (crp) => {
        return (
          crp.class_admitted === this.state.current_class &&
          crp.term === this.state.term &&
          new Date(crp.date_processed).getFullYear() ===
            parseInt(this.state.year)
        );
      });

      let jhs = {};
      let ups = {};
      let pps = {};

      let math_scores = [],
        eng_scores = [],
        sci_scores = [],
        soc_scores = [],
        fantse_scores = [],
        rme_scores = [],
        bdt_scores = [],
        french_scores = [],
        ict_scores = [],
        owop_scores = [],
        history_scores = [],
        cr_scores = [],
        ctech_scores = [],
        ph_scores = [],
        overrall_aggs = [],
        writ_scores = [];

      if (current_report.length > 0) {
        //separation of courses by class_category
        //junior High Courses.
        if (this.state.class_category === "junior_high") {
          _.map(current_report, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            sci_scores.push(cp.sts);
            soc_scores.push(cp.ssts);
            fantse_scores.push(cp.fnts);
            rme_scores.push(cp.rmts);
            french_scores.push(cp.fts);
            ict_scores.push(cp.ictts);
            bdt_scores.push(cp.bdts);
            ctech_scores.push(cp.ctts);
            ph_scores.push(cp.phts);
            owop_scores.push(cp.owts);
            cr_scores.push(cp.crats);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        } else if (
          this.state.class_category === "upper_primary" ||
          this.state.class_category === "lower_primary"
        ) {
          _.map(current_report, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            sci_scores.push(cp.sts);
            fantse_scores.push(cp.fnts);
            rme_scores.push(cp.rmts);
            french_scores.push(cp.fts);
            ict_scores.push(cp.ictts);
            owop_scores.push(cp.owts);
            cr_scores.push(cp.crats);
            history_scores.push(cp.hts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        } else if (this.state.class_category === "pre_school") {
          _.map(current_report, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            writ_scores.push(cp.wrts);
            owop_scores.push(cp.owts);
            cr_scores.push(cp.crats);
            ph_scores.push(cp.phts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        }

        jhs = {
          maths: this.sortResults(math_scores),
          science: this.sortResults(sci_scores),
          english: this.sortResults(eng_scores),
          social: this.sortResults(soc_scores),
          rme: this.sortResults(rme_scores),
          french: this.sortResults(french_scores),
          fantse: this.sortResults(fantse_scores),
          ict: this.sortResults(ict_scores),
          bdt: this.sortResults(bdt_scores),
          creative_arts: this.sortResults(cr_scores),
          phealth: this.sortResults(ph_scores),
          ctech: this.sortResults(ctech_scores),
          overrall: this.sortResults(overrall_aggs),
        };

        ups = {
          maths: this.sortResults(math_scores),
          creative_arts: this.sortResults(cr_scores),
          science: this.sortResults(sci_scores),
          english: this.sortResults(eng_scores),
          rme: this.sortResults(rme_scores),
          french: this.sortResults(french_scores),
          fantse: this.sortResults(fantse_scores),
          ict: this.sortResults(ict_scores),
          owop: this.sortResults(owop_scores),
          history: this.sortResults(history_scores),
          overrall: this.sortResults(overrall_aggs),
        };

        pps = {
          maths: this.sortResults(math_scores),
          creative_arts: this.sortResults(cr_scores),
          writing: this.sortResults(writ_scores),
          phonics: this.sortResults(ph_scores),
          owop: this.sortResults(owop_scores),
          overrall: this.sortResults(overrall_aggs),
          english: this.sortResults(eng_scores),
        };

        

        switch (this.state.class_category) {
          case "upper_primary":
          case "lower_primary":
            generate_report(
              ups,
              this.state.current_report,
              method
            );
            break;
          case "junior_high":
            generate_report(
              jhs,
              this.state.current_report,
              method
            );
            break;
          default:
            generate_pre_report(
              pps,
              this.state.current_report,
              method
            );
        }
        // console.log(all_scores)
        // console.log(this.state.current_report)
        // generate_filewith(all_scores,this.state.current_report)
      } else {
        console.log("not -reports");
      }
    }
  };



  render() {
    return (
      <Grid className="open-grids">
        <Confirm
          open={this.state.confirm_delete}
          cancelButton={
            <Button icon color="red" size="mini" circular>
              <Icon name="cancel" /> No, Don't!{" "}
            </Button>
          }
          confirmButton={
            <Button icon color="green" size="mini" circular>
              <Icon name="checkmark" />
              {"  "}Yes, Continue{" "}
            </Button>
          }
          onConfirm={() =>
            this.setState({ confirm_delete: false }, (e) =>
              this.deleteBetaReport(this.state.report_id)
            )
          }
          onCancel={() => this.setState({ confirm_delete: false })}
          size="mini"
          header="Report Deletion Confirm"
          content="This will delete report PERMANENTLY and might affect existing reports. Continue ?"
        />

        <Grid.Row columns={2}>
          <Grid.Column computer={7} mobile={16}>
            <Segment raised inverted={this.props.dark_mode}>
              <Header as="h3" content="Students List" />
              <Divider horizontal>o</Divider>

              <List divided relaxed inverted={this.props.dark_mode}>
                {this.props.students.map((std, idx) => (
                  <List.Item
                    as="a"
                    key={idx}
                    onClick={() =>
                      this.setState(
                        {
                          class_category: std.class_category,
                          current_student: std,
                        },
                        () => this.getStudentReport()
                      )
                    }
                  >
                    <Image
                      avatar
                      src={std.profile_image}
                      verticalAlign="middle"
                    />
                    <List.Content>
                      {std.last_name + " " + std.other_names}
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </Segment>
          </Grid.Column>

          <Grid.Column computer={9} mobile={16}>
            <Segment raised inverted={this.props.dark_mode}>
              {this.state.new_loaded ? (
                this.state.current_report !== null ||
                this.state.current_report.student_id !== undefined ? (
                  <Item.Group>
                    <Item
                      image={this.state.current_report.profile_image}
                      header={
                        this.state.current_report.last_name +
                        "  " +
                        this.state.current_report.other_names
                      }
                      content={
                        <Table
                          fixed
                          celled
                          unstackable
                          singleLine
                          inverted={this.props.dark_mode}
                        >
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Course</Table.HeaderCell>
                              <Table.HeaderCell>Grade</Table.HeaderCell>
                              <Table.HeaderCell>Score</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>Mathematics</Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.mgr}
                              </Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.mts}
                              </Table.Cell>
                            </Table.Row>

                            {this.state.current_report.class_category ===
                            "pre_school" ? (
                              <React.Fragment>
                                <Table.Row>
                                  <Table.Cell>Literacy & Language</Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.egr}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.ets}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>Phonics</Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.phgr}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.phts}
                                  </Table.Cell>
                                </Table.Row>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Table.Row>
                                  <Table.Cell>English</Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.egr}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.ets}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell>Int. Science</Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.sgr}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {this.state.current_report.sts}
                                  </Table.Cell>
                                </Table.Row>
                              </React.Fragment>
                            )}

                            {this.state.current_report.class_category ===
                              "lower_primary" ||
                            this.state.current_report.class_category ===
                              "upper_primary" ? (
                              <Table.Row>
                                <Table.Cell>Our World Our People</Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.owgr}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.owts}
                                </Table.Cell>
                              </Table.Row>
                            ) : this.state.current_report.class_category ===
                              "junior_high" ? (
                              <Table.Row>
                                <Table.Cell>Social Studies</Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.ssgr}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.state.current_report.ssts}
                                </Table.Cell>
                              </Table.Row>
                            ) : (
                              <Table.Row>
                                <Table.Cell colSpan="2" />
                              </Table.Row>
                            )}

                            <Table.Row>
                              <Table.Cell colSpan="2">
                                Overall Aggregate
                              </Table.Cell>
                              <Table.Cell>
                                {this.state.current_report.overrall_aggregate}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                          <Table.Footer>
                            <Table.Row>
                              <Table.Cell colSpan="2">
                                <Button
                                  onClick={() =>
                                    this.getCurrentClassReport("print")
                                  }
                                  color="teal"
                                  size="tiny"
                                >
                                  Generate Report
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Footer>
                        </Table>
                      }
                    />
                  </Item.Group>
                ) : (
                  <h3>Empty Report Received</h3>
                )
              ) : (
                <Header
                  content="Enter Report Received"
                  textAlign="center"
                  subheader="Report may have not been processed"
                />
              )}
            </Segment>

            {this.state.current_class === "" ? null : (
              <Segment raised inverted={this.props.dark_mode}>
                <Table unstackable singleLine fixed striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell colSpan="4">
                        Reports of Current Class - Beta
                      </Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                      <Table.HeaderCell>Name of Student</Table.HeaderCell>
                      <Table.HeaderCell>Date Processed</Table.HeaderCell>
                      <Table.HeaderCell>Download</Table.HeaderCell>
                      <Table.HeaderCell>Delete</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.getBetaReport().map((rpt) => (
                      <Table.Row key={rpt.student_id}>
                        <Table.Cell>
                          {rpt.last_name + " " + rpt.other_names}
                        </Table.Cell>
                        <Table.Cell>{rpt.date_processed}</Table.Cell>
                        <Table.Cell>
                          <Button
                            size="tiny"
                            onClick={() =>
                              this.setState(
                                { class_category: rpt.class_category },
                                () => {
                                  this.downloadBetaReport(rpt);
                                }
                              )
                            }
                            icon
                            circular
                          >
                            <Icon name="download" color="green" />
                          </Button>
                        </Table.Cell>
                        <Table.Cell>
                          <Button
                            size="tiny"
                            onClick={() =>
                              this.setState({
                                report_id: rpt._id,
                                confirm_delete: true,
                              })
                            }
                            icon
                            circular
                          >
                            <Icon name="trash" color="red" />
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
