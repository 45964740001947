import React from "react";
import {
	Segment,
	Grid,
	Table,
	Header,
	List,
	Modal,
	Button,
	Input,
	Form,
} from "semantic-ui-react";
import TableForm from "../_calls/fill-tables";
import { _days } from "../_calls/data";
import _ from "lodash";
import { db, storage } from "../services";
export default class TimeTable extends React.Component {
	constructor() {
		super();
		this.state = {
			day: "",
			open: false,
			table_file: null,
			docs: [],
		};
	}

	componentDidMount() {
		db.collection("file_docs").onSnapshot((qss) => {
			let doc_ = [];
			qss.forEach((doc) => {
				doc_.push({ ...doc.data(), _id: doc.id });
			});
			this.setState({ docs: doc_ }, () => (doc_ = []));
		});
	}

	getDay = (day) => {
		return new Date().getDay() === day;
	};

	getTableFile = (event) => {
		let file = event.target.files[0];
		let Ftype = file.type;
		let Fsize = file.size / 1024;

		//check file type
		if (Ftype !== "application/pdf") {
			alert("Please, Upload a PDF instead!");
		} else {
			if (Fsize > 6000) {
				alert("Maximum file size accepted is five (5) MB");
			} else {
				this.setState({ table_file: file });
			}
		}
	};

	uploadTableFile = (event) => {
		let storageRef = storage.child(
			"time_tables/" +
				this.props.profile.class_assigned +
				"-" +
				new Date().getFullYear()
		);
		storageRef.put(this.state.table_file).then(() => {
			storageRef.getDownloadURL().then((url) => {
				db.collection("file_docs")
					.add({
						class: this.props.profile.class_assigned,
						table_file: url,
						date_uploaded: new Date().toLocaleDateString(),
						time: new Date().toLocaleTimeString(),
					})
					.then(() => {
						alert("Table Document Uploaded");
					});
			});
		});
	};

	getTermType = (cl) => {
        
		return this.props.settings.current_term;
	};

	getTimeTable = (day) => {
		const cTime = _.find(this.props.tables, (td) => {
			return td.day === day;
		});

		return cTime !== undefined ? cTime : {};
	};

	getUploadState = () => {
		let status = _.find(
			this.state.docs,
			(did) =>
				did.class === this.props.profile.class_assigned &&
				new Date(did.date_uploaded).getFullYear() === new Date().getFullYear()
		);

		return status === undefined ? false : true;
	};

	render() {
		return (
			<Grid padded>
				<Grid.Column computer={4} mobile={16}>
					<Segment raised>
						<Header
							as="h3"
							content="Time Tables"
							subheader={this.getTermType(this.props.profile.class_assigned)}
						/>
						<List divided relaxed animated selection>
							<List.Item
								active={this.getDay(1)}
								onClick={(e) => this.setState({ day: "Monday", open: true })}
							>
								<List.Icon
									color="green"
									name="sticky note"
									size="large"
									verticalAlign="middle"
								/>
								<List.Content>
									<List.Header as="a">Monday</List.Header>
								</List.Content>
							</List.Item>
							<List.Item
								active={this.getDay(2)}
								onClick={(e) => this.setState({ day: "Tuesday", open: true })}
							>
								<List.Icon
									color="teal"
									name="sticky note"
									size="large"
									verticalAlign="middle"
								/>
								<List.Content>
									<List.Header as="a">Tuesday</List.Header>
								</List.Content>
							</List.Item>
							<List.Item
								active={this.getDay(3)}
								onClick={(e) => this.setState({ day: "Wednesday", open: true })}
							>
								<List.Icon
									color="orange"
									name="sticky note"
									size="large"
									verticalAlign="middle"
								/>
								<List.Content>
									<List.Header as="a">Wednesday</List.Header>
								</List.Content>
							</List.Item>

							<List.Item
								active={this.getDay(4)}
								onClick={(e) => this.setState({ day: "Thursday", open: true })}
							>
								<List.Icon
									color="olive"
									name="sticky note"
									size="large"
									verticalAlign="middle"
								/>
								<List.Content>
									<List.Header as="a">Thursday</List.Header>
								</List.Content>
							</List.Item>

							<List.Item
								active={this.getDay(5)}
								onClick={(e) => this.setState({ day: "Friday", open: true })}
							>
								<List.Icon
									color="violet"
									name="sticky note"
									size="large"
									verticalAlign="middle"
								/>
								<List.Content>
									<List.Header as="a">Friday</List.Header>
								</List.Content>
							</List.Item>

							{this.getUploadState() === true ? (
								<List.Item>
									<List.Icon
										name="checkmark"
										color="teal"
										size="large"
										verticalAlign="middle"
									/>
									<List.Content>
										<List.Header as="a">File Uploaded: Yes</List.Header>
									</List.Content>
								</List.Item>
							) : (
								<List.Item>
									<List.Icon
										name="cancel"
										color="brown"
										size="large"
										verticalAlign="middle"
									/>
									<List.Content>
										<List.Header as="a">File Uploaded: No! </List.Header>
									</List.Content>
								</List.Item>
							)}
						</List>
						<br />
						<br />
						<Form>
							<Input
								fluid
								size="mini"
								accept="*/pdf"
								onChange={this.getTableFile}
								action={{
									icon: "upload",
									color: "green",
									disabled: !this.state.table_file,
									onClick: () => this.uploadTableFile,
								}}
								type="file"
							/>
						</Form>
					</Segment>
				</Grid.Column>
				<Grid.Column computer={12} mobile={16}>
					<Segment raised>
						<Table celled unstackable textAlign={"center"}>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell textAlign="left" colSpan="8">
										<Header
											as="h1"
											textAlign={"left"}
											content={
												"Time Table for " + this.props.profile.class_assigned
											}
											subheader={_days[new Date().getDay()].value}
										/>
									</Table.HeaderCell>
								</Table.Row>
								<Table.Row>
									<Table.HeaderCell>Lesson 1</Table.HeaderCell>
									<Table.HeaderCell>Lesson 2</Table.HeaderCell>
									<Table.HeaderCell>Lesson 3</Table.HeaderCell>
									<Table.HeaderCell>Lesson 4</Table.HeaderCell>
									<Table.HeaderCell>Lesson 5</Table.HeaderCell>
									<Table.HeaderCell>Lesson 6</Table.HeaderCell>
									<Table.HeaderCell>Lesson 7</Table.HeaderCell>
									<Table.HeaderCell>Lesson 8</Table.HeaderCell>
								</Table.Row>
							</Table.Header>

							<Table.Body>
								<Table.Row active={this.getDay(1)}>
									<Table.Cell>
										{this.getTimeTable("Monday").lesson_1}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Monday").lesson_2}
									</Table.Cell>
									<Table.Cell>B</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Monday").lesson_3}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Monday").lesson_4}
									</Table.Cell>
									<Table.Cell>B</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Monday").lesson_5}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Monday").lesson_6}
									</Table.Cell>
								</Table.Row>

								<Table.Row active={this.getDay(2)}>
									<Table.Cell>
										{this.getTimeTable("Tuesday").lesson_1}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Tuesday").lesson_2}
									</Table.Cell>
									<Table.Cell>R</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Tuesday").lesson_3}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Tuesday").lesson_4}
									</Table.Cell>
									<Table.Cell>R</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Tuesday").lesson_5}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Tuesday").lesson_6}
									</Table.Cell>
								</Table.Row>

								<Table.Row active={this.getDay(3)}>
									<Table.Cell>
										{this.getTimeTable("Wednesday").lesson_1}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Wednesday").lesson_2}
									</Table.Cell>
									<Table.Cell>E</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Wednesday").lesson_3}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Wednesday").lesson_4}
									</Table.Cell>
									<Table.Cell>E</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Wednesday").lesson_5}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Wednesday").lesson_6}
									</Table.Cell>
								</Table.Row>

								<Table.Row active={this.getDay(4)}>
									<Table.Cell>
										{this.getTimeTable("Thursday").lesson_1}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Thursday").lesson_2}
									</Table.Cell>
									<Table.Cell>A</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Thursday").lesson_3}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Thursday").lesson_4}
									</Table.Cell>
									<Table.Cell>A</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Thursday").lesson_5}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Thursday").lesson_6}
									</Table.Cell>
								</Table.Row>

								<Table.Row active={this.getDay(5)}>
									<Table.Cell>
										{this.getTimeTable("Friday").lesson_1}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Friday").lesson_2}
									</Table.Cell>
									<Table.Cell>K</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Friday").lesson_3}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Friday").lesson_4}
									</Table.Cell>
									<Table.Cell>K</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Friday").lesson_5}
									</Table.Cell>
									<Table.Cell>
										{this.getTimeTable("Friday").lesson_6}
									</Table.Cell>
								</Table.Row>
							</Table.Body>
						</Table>
					</Segment>
				</Grid.Column>
				<Modal
					closeOnDimmerClick={false}
					basic
					onClose={() => this.setState({ open: false })}
					onOpen={() => this.setState({ open: true })}
					open={this.state.open}
					size="mini"
				>
					<Modal.Header>Time Table for {this.state.day}</Modal.Header>
					<Modal.Content>
						<TableForm
							day={this.state.day}
							tables={_.filter(this.props.tables, (td) => {
								return (
									td.term ===
									this.getTermType(this.props.profile.class_assigned)
								);
							})}
							term={this.props.term}
							current_class={this.props.profile.class_assigned}
						/>
					</Modal.Content>
					<Modal.Actions>
						<Button
							basic
							icon="remove"
							circular
							size="tiny"
							color="red"
							onClick={() => this.setState({ open: false })}
						/>
					</Modal.Actions>
				</Modal>
			</Grid>
		);
	}
}
