import React, { Component } from "react";
import { Badge } from "@material-ui/core";
import _ from "lodash";
import {
  Attendance,
  Records,
  Division,
  Dashboard,
  Reports,
  TimeTable,
  Examination,
  Assignments,
} from "../components";
import { db, auth } from "../services";
import {
  Menu,
  Icon,
  Sidebar,
  Grid,
  Header,
  Segment,
  Confirm,
  Button,
} from "semantic-ui-react";
import Notification from "../_calls/notification";

export default class Main extends Component {
  constructor() {
    super();
    this.state = {
      currentUser: auth().currentUser.email.slice(0, 11).toUpperCase(),
      active: "dashboard",
      dark_mode: false,
      open: true,
      show_side: false,
      open_profile: false,
      current_view: "dashboard",
      open_notes: false,
      loading: true,
      fees: [],
      notifications: [],
      current_staff: {},
      _students: [],
      _attendance: [],
      settings: {},
      _history: [],
      _notes: [],
      assignments: [],
      time_tables: [],
      confirm_logout: false,
    };
  }

  componentDidMount() {
    db.collection("staff_registration")
      .where("staff_id", "==", this.state.currentUser)
      .get()
      .then((resp) => {
        let c_s = {};
        resp.forEach((doc) => {
          c_s = { ...doc.data(), _id: doc.id };
        });
        this.setState({ current_staff: c_s });
      })
      .then(() => {
        db.collection("student_registration")
          .where(
            "class_admitted",
            "==",
            this.state.current_staff.class_assigned
          )
          .onSnapshot((qss) => {
            let students = [];
            qss.forEach((doc) => {
              students.push({ ...doc.data(), _id: doc.id });
            });
            this.setState(
              {
                _students: _.filter(students, (std) => {
                  return std.status === undefined || std.status === "active";
                }),
              },
              () => (students = [])
            );
          });

        db.collection("examination_reports")
          .where(
            "class_admitted",
            "==",
            this.state.current_staff.class_assigned
          )
          .onSnapshot((qss) => {
            let rhistory = [];
            qss.forEach((doc) => {
              rhistory.push({ ...doc.data(), _id: doc.id });
            });
            this.setState({ _history: rhistory }, () => (rhistory = []));
          });

        db.collection("attendance")
          .where("class", "==", this.state.current_staff.class_assigned)
          .onSnapshot((qss) => {
            let rattendance = [];
            qss.forEach((doc) => {
              rattendance.push({ ...doc.data(), _id: doc.id });
            });
            this.setState(
              { _attendance: rattendance },
              () => (rattendance = [])
            );
          });

        db.collection("time_tables")
          .where("class_name", "==", this.state.current_staff.class_assigned)
          .onSnapshot((qss) => {
            let rtable = [];
            qss.forEach((doc) => {
              rtable.push({ ...doc.data(), _id: doc.id });
            });
            this.setState(
              {
                time_tables: _.filter(rtable, (td) => {
                  return (
                    new Date().getFullYear() ===
                    new Date(td.date_set).getFullYear()
                  );
                }),
              },
              () => (rtable = [])
            );
          });

        db.collection("staff_notes")
          .where("author", "==", this.state.currentUser)
          .onSnapshot((qss) => {
            let _notes = [];
            qss.forEach((doc) => {
              _notes.push({ ...doc.data(), _id: doc.id });
            });
            this.setState({ _notes: _notes }, () => (_notes = []));
          });
      });

    db.collection("class_assignments").onSnapshot((qss) => {
      let assDocs = [];
      qss.forEach((doc) => {
        if (doc.data().sent_by === this.state.currentUser) {
          assDocs.push({ ...doc.data(), _id: doc.id });
        }
      });
      this.setState({ assignments: assDocs }, () => (assDocs = []));
    });

    db.collection("app_settings")
      .doc("sch_settings")
      .get()
      .then((resp) => {
        this.setState({ settings: resp.data() });
      });

    db.collection("notifications").onSnapshot((qss) => {
      let notes = [];
      qss.forEach((doc) => {
        notes.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ notifications: notes }, () => (notes = []));
    });

    db.collection("fee_payment").onSnapshot((qss) => {
      let fData = [];
      qss.forEach((doc) => {
        fData.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ fees: fData });
      fData = [];
    });
  }

  toggleSidebar = (e) => {
    this.setState({ open: !this.state.open });
  };

  changeView = (name) => (event) => {
    this.setState({ current_view: name, active: name, show_side: false });
  };

  isSemester = (cls) =>
    cls.charAt(0) === "J" || ["Basic 7", "Basic 8", "Basic 9"].includes(cls);



  render() {
    const { current_staff, settings, assignments } = this.state;
    const students = _.sortBy(this.state._students, (nm) => {
      return nm.last_name;
    });
    let current_view;
    switch (this.state.current_view) {
      case "dashboard":
        current_view = (
          <Dashboard
            term={settings?.current_term || ""}
            profile={current_staff}
            students={students}
            dark={this.state.dark_mode}
            notes={this.state._notes}
            _history={this.state._history}
            passMark={settings.pass_mark}
            settings={settings}
          />
        );
        break;

      case "examination":
        current_view = (
          <Examination
            _history={this.state._history}
            dark={this.state.dark_mode}
            attendance={this.state._attendance}
            students={students}
            profile={current_staff}
            sett={settings}
          />
        );
        break;

      case "reports":
        current_view = (
          <Reports
            dark_mode={this.state.dark_mode}
            profile={current_staff}
            students={students}
            sett={settings}
            _history={this.state._history}
          />
        );
        break;

      case "time-tables":
        current_view = (
          <TimeTable
            profile={current_staff}
            settings={settings}
            tables={this.state.time_tables}
          />
        );
        break;

      case "attendance":
        current_view = (
          <Attendance
            dark={this.state.dark_mode}
            students={students}
            profile={current_staff}
            attendance={this.state._attendance}
            sett={settings}
          />
        );
        break;

      case "records":
        current_view = (
          <Records
            settings={settings}
            students={students}
            profile={current_staff}
          />
        );
        break;

      case "division":
        current_view = (
          <Division
            fees={this.state.fees}
            settings={settings}
            dark={this.state.dark_mode}
            students={students}
            profile={current_staff}
          />
        );
        break;
      case "assignments":
        current_view = (
          <Assignments
            assign={assignments}
            profile={current_staff}
            students={students}
          />
        );
        break;

      default:
        current_view = (
          <Dashboard
            term={settings?.current_term || " "}
            profile={current_staff}
            students={students}
            dark={this.state.dark_mode}
            notes={this.state._notes}
            _history={this.state._history}
            passMark={this.state.settings.pass_mark}
            settings={this.state.settings}
          />
        );
    }

    return (
      <>
        <Confirm
          closeOnDimmerClick={false}
          size="mini"
          open={this.state.confirm_logout}
          header="Log out of Portal"
          content="This action will log you out,Continue ? "
          confirmButton={
            <Button circular size="mini" color="green">
              Yes, Continue{" "}
            </Button>
          }
          cancelButton={
            <Button color="red" circular size="mini">
              No, Stay{" "}
            </Button>
          }
          onCancel={() => this.setState({ confirm_logout: false })}
          onConfirm={() =>
            this.setState({ confirm_logout: false }, () => auth().signOut())
          }
        />

        <Sidebar
          as={Menu}
          fixed={"left"}
          onHide={() => this.setState({ show_side: false })}
          animation="overlay"
          icon="labeled"
          vertical
          inverted={this.state.dark_mode}
          visible={this.state.show_side}
          width="thin"
        >
          <Menu.Item
            as="a"
            onClick={this.changeView("dashboard")}
            active={this.state.active === "dashboard"}
          >
            <Icon name="pie graph" />
            Dashboard
          </Menu.Item>

          <Menu.Item
            as="a"
            active={this.state.active === "division"}
            onClick={this.changeView("division")}
          >
            <Icon name="group" />
            Your Division
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("attendance")}
            active={this.state.active === "attendance"}
          >
            <Icon name="checkmark" />
            Attendance
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("examination")}
            active={this.state.active === "examination"}
          >
            <Icon name="write" />
            Examination
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("reports")}
            active={this.state.active === "reports"}
          >
            <Icon name="server" />
            Reports
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("records")}
            active={this.state.active === "record"}
          >
            <Icon name="th" />
            Records
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("time-tables")}
            active={this.state.active === "time-tables"}
          >
            <Icon name="table" />
            Time Tables
          </Menu.Item>

          <Menu.Item
            as="a"
            onClick={this.changeView("assignments")}
            active={this.state.active === "assignments"}
          >
            <Icon name="book" />
            Assignments
          </Menu.Item>
        </Sidebar>
        {/* <AppSettings mode={mode} open_settings={this.state.sett_open} close_settings={(name) => this.setState({sett_open:false})} />
          <UserProfile open_profile={this.state.open_profile} close_profile={(name) => this.setState({ open_profile: false })} /> */}
        <Grid>
          <Grid.Column width={16}>
            {/* Top Navigation Bar */}

            <Menu fixed="top" borderless inverted={this.state.dark_mode}>
              <Menu.Item className="school-name hide-on-mobile">
                <Header
                  as="h4"
                  inverted={this.state.dark_mode}
                  content={"Western Region International School".toUpperCase()}
                  subheader="Building Tomorrow's Future Today"
                />
              </Menu.Item>

              <Menu.Item className="school-name hide-on-desktop">
                <Header
                  as="h4"
                  inverted={this.state.dark_mode}
                  content={"W R I S".toUpperCase()}
                  subheader="Staff Portal"
                />
              </Menu.Item>

              <Menu.Menu position="right">
                <Menu.Item
                  as="a"
                  onClick={(e) =>
                    this.setState({ open_notes: !this.state.open_notes })
                  }
                >
                  <Badge
                    badgeContent={
                      this.state.notifications !== null
                        ? this.state.notifications.length
                        : 0
                    }
                    color={"error"}
                  >
                    <Icon name="bell" size="large" />
                  </Badge>
                </Menu.Item>

                <Menu.Item
                  as="a"
                  title="Log Out"
                  onClick={() => this.setState({ confirm_logout: true })}
                >
                  <Icon name="log out" color="red" />
                </Menu.Item>

                <Menu.Item
                  as="a"
                  onClick={(e) =>
                    this.setState({ dark_mode: !this.state.dark_mode })
                  }
                >
                  <Icon name="lightbulb outline" size="large" />
                </Menu.Item>

                <Menu.Item
                  as="a"
                  onClick={(e) => {
                    this.setState({ show_side: !this.state.show_side });
                  }}
                >
                  {this.state.show_side ? (
                    <Icon name="cancel" size="large" color="red" />
                  ) : (
                    <Icon name="bars" size="large" color="green" />
                  )}
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <Segment className="wall" inverted={this.state.dark_mode}>
              <Grid padded stackable>
                <Grid.Column computer={16} tablet={16}>
                  {current_view}
                </Grid.Column>
                <Notification
                  open={this.state.open_notes}
                  messages={this.state.notifications}
                />
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={16}>
            <Menu fixed="bottom" borderless inverted={this.state.dark_mode}>
              <Menu.Menu position="right">
                <Menu.Item className="hide-on-mobile">
                  Forward any queries to&nbsp;
                  <a
                    target="_blank"
                    href="mailto:logickidsteam@gmail.com"
                    rel="noopener noreferrer"
                  >
                    &nbsp;Logic Kids Team
                  </a>
                  &nbsp;or call&nbsp;
                  <a
                    target="_blank"
                    href="tel:050-934-3841"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    &nbsp;Bill Greatness
                  </a>
                </Menu.Item>

                <Menu.Item>
                  &copy; {new Date().getFullYear()} Logicians Ghana Limited
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}
